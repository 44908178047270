import { AppShell, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import {
  IconChartPie,
  IconDiscount2,
  IconGoGame,
  IconMail,
  IconMailForward,
  IconMapSearch,
  IconReceipt,
  IconTruckDelivery,
  IconUsers,
  IconUserSearch,
  IconWallet,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IAuth } from "../../interfaces/IAuth";
import { useScreenDetector } from "../screen-detector";

const items = [
  { title: "Дашбоард", icon: IconChartPie, color: "violet", link: "/", code: "NETWORK_DASHBOARD" },
  { title: "Ирсэн урилга", icon: IconMail, color: "indigo", link: "/came", code: "NETWORK_INVITATION_RECEIVED" },
  { title: "Илгээсэн урилга", icon: IconMailForward, color: "blue", link: "/sent", code: "NETWORK_INVITATION_SENT" },
  { title: "Манай харилцагчид", icon: IconUserSearch, color: "green", link: "/our-buyers", code: "NETWORK_LIST" },
  { title: "Манай нийлүүлэгчид", icon: IconTruckDelivery, color: "teal", link: "/our-suppliers", code: "NETWORK_LIST" },
  { title: "Төлбөрийн нөхцөл", icon: IconDiscount2, color: "cyan", link: "/nw-payment-term", code: "NETWORK_PAYMENT_TERM" },
  { title: "Данс тохиргоо", icon: IconWallet, color: "cyan", link: "/account", code: "NETWORK_ACCOUNT" },
  { title: "Хариуцсан, ажилтнууд", icon: IconUsers, color: "orange", link: "/distribution-area", code: "NETWORK_STAFF" },
  { title: "Ангилал, зэрэглэл", icon: IconGoGame, color: "pink", link: "/rank", code: "NETWORK_CLASSIFICATION" },
  { title: "Лавлах мэдээлэл", icon: IconMapSearch, color: "orange", link: "/reference", code: "NETWORK_REFERENCE" },
];

export function Navigation({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) {
  const { businessType } = useSelector((state: any) => state.auth);
  const { isMobile } = useScreenDetector();

  return (
    <AppShell.Navbar style={{ paddingTop: "1rem", overflowY: "auto", overflowX: "hidden" }}>
      {items
        .filter((item) => item.link !== (businessType === "BUYER" ? "/our-buyers" : "/our-suppliers"))
        .map((item, index) => (
          <NavItem item={item} key={index} setOpened={setOpened} opened={isMobile && opened} />
        ))}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  color: string;
  link: string;
  disabled?: boolean;
  code?: string;
};

function CheckRole({ children, code }: { children: JSX.Element; code: any }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const network: any[] = user?.permissions?.filter((item: any) => item.module === "NETWORK") || [];

  if (network?.some((som: any) => som?.menu === code)) return <>{children}</>;
  else return <></>;
}

function NavItem({ item, setOpened, opened = false }: { item: INavItem; setOpened: (e: boolean) => void; opened: boolean }) {
  const { classes, cx } = useStyles({ color: "primary" });

  return (
    <CheckRole code={item.code}>
      <NavLink
        className={cx(classes.item, item.disabled && "disabled", opened && classes.row)}
        to={!item.disabled ? item.link : "#"}
        onClick={() => {
          setOpened(false);
        }}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={opened ? 0 : 7} lh="16px" fw="400" style={{ paddingLeft: 20, paddingRight: 20 }}>
          {item.title}
        </Text>
      </NavLink>
    </CheckRole>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }, u) => {
  const icon = u.ref("icon");

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "20px",
      [`&.disabled .${icon}`]: {
        backgroundColor: `${theme.colors[color][0]}!important`,
        color: `${theme.colors[color][6]}!important`,
      },
      [`&:hover .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`&.active .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
    },
    icon: {
      ref: icon,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors[color][6],
      backgroundColor: theme.colors[color][0],
      borderRadius: 4,
      padding: 8,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      gap: 15,
      justifyContent: "start",
      borderRadius: theme.radius.sm,

      [`&:hover`]: {
        background: theme.colors[color][0],
        transform: "revert",
        animation: "ease-in",
      },
    },
  };
});
