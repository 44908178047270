import { ActionIcon, Button, Drawer, Group } from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { RankForm } from "../../components/rank/rank-form";
import { RankList } from "../../components/rank/rank-list";
import { useScreenDetector } from "../../components/screen-detector";
import { IAuth } from "../../interfaces/IAuth";

export const Rank = () => {
  const breadcrumbs = useBreadcrumb();
  const { businessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = useState<any[]>([]);
  const { isMobile } = useScreenDetector();
  const [selected, setSelected] = useState<string[]>([]);
  const refTable = useRef<any>(null);
  const NET_CLS_SET = usePermission("NET_CLS_SET");

  const reload = async () => {
    await refTable.current.reload();
    setSelected([]);
  };

  return (
    <PageLayout
      title={businessType === "SUPPLIER" ? "Худалдан авагчийн ангилал зэрэглэл" : "Нийлүүлэгчийн ангилал зэрэглэл"}
      subTitle="Ангилал зэрэглэл"
      breadcrumb={breadcrumbs.map((item: any, index: number) => {
        if (index === breadcrumbs?.length - 1)
          return {
            label: businessType === "SUPPLIER" ? "Худалдан авагч" : "Нийлүүлэгч",
          };
        return item;
      })}
      extra={[
        NET_CLS_SET.hasAccess && (
          <Group key={10}>
            {!isMobile ? (
              <Button
                disabled={selected?.length === 0}
                onClick={() => {
                  setAction(["new", selected]);
                }}>
                Тохиргоо хийх
              </Button>
            ) : (
              <ActionIcon
                disabled={selected?.length === 0}
                onClick={() => {
                  setAction(["new", selected]);
                }}
                variant="filled"
                size={36}
                color="">
                <IconSettings />
              </ActionIcon>
            )}
          </Group>
        ),
      ]}>
      {NET_CLS_SET.hasAccess ? (
        <RankList refTable={refTable} selected={selected} setSelected={setSelected} action={action} setAction={setAction} reload={() => reload()} />
      ) : (
        NET_CLS_SET.accessDenied()
      )}

      <Drawer
        size={"1100px"}
        withCloseButton={false}
        title={false}
        position="right"
        opened={action[0] === "new"}
        onClose={() => {
          setAction([]);
        }}
        padding="lg">
        <RankForm title="Ангилал зэрэглэл тохируулах" action={action} setAction={setAction} subTitle="" reload={() => reload()} />
      </Drawer>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Network удирдлага",
  },
  {
    label: "Ангилал, зэрэглэл",
  },
  {
    label: "Худалдан авагч",
  },
];
