import { Avatar, Badge, Button, CloseButton, Divider, Flex, Grid, Group, Paper, Space, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { NetworkSetApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  businessIds: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  areaRegionId: yup.string().required("Заавал бөглөнө!").nullable(),
  areaDirectionId: yup.string().required("Заавал бөглөнө!").nullable(),
  areaDesc: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  businessIds: string[];
  areaRegionId: string | undefined;
  areaDirectionId: string | undefined;
  areaDesc: string | undefined;
};

type IFormProps = {
  action: any[];
  setAction: (e: any) => void;
  title: string;
  subTitle: string;
  reload?: any;
};

export function DistributionAreaForm({ action, setAction, title, subTitle, reload }: IFormProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { distributionAreas, businessStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = useState<IFormData>({
    areaRegionId: undefined,
    areaDirectionId: undefined,
    areaDesc: undefined,
    businessIds:
      action[1]?.map((item: any) => {
        return item.id;
      }) ?? [],
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    let now = new Date();
    try {
      await NetworkSetApi.distribution_area({
        businessIds: values.businessIds ?? [],
        areaRegionId: values.areaRegionId,
        areaDirectionId: values.areaDirectionId,
        areaDesc: values.areaDesc,
      });
      message.success(`Амжилттай хадгалагдлаа. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`);
      setAction([]);
      reload();
    } catch (error: any) {
      message.error(error.message ?? "Хүсэлт амжилтгүй.");
    }
    setLoading(false);
  };

  const columns = useHeader({ businessStatus });

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setAction && setAction([])} />]}>
            <Paper>
              <Divider mb={"lg"} />
              <Grid>
                <Grid.Col span={12}>
                  <Text c={"dimmed"}>Бүс, чиглэл тохируулах</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <SelectField
                    onChange={() => {
                      setFieldValue("areaDirectionId", undefined);
                    }}
                    name="areaRegionId"
                    required
                    label="Бүс сонгох"
                    placeholder="Сонгоно уу"
                    clearable
                    options={distributionAreas
                      .filter((item: { isParent: boolean }) => item.isParent)
                      .map((item: { name: string; id: string }) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      })}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <SelectField
                    disabled={!values.areaRegionId}
                    name="areaDirectionId"
                    required
                    label="Чиглэл сонгох"
                    placeholder="Сонгоно уу"
                    clearable
                    options={distributionAreas
                      .filter((item: { parentId: string }) => values.areaRegionId === item.parentId)
                      .map((item: { name: string; id: string }) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      })}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <TextareaField name="areaDesc" required label="Тайлбар" placeholder="Тайлбар бичнэ үү" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text c={"dimmed"}>Сонгосон бизнесүүд</Text>
                  <Space h={"xs"} />
                  <Table pagination={false} name="areaDesc.list" columns={columns} filters={{}} dataSource={(action[1] && action[1]) ?? []} />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Group justify="right">
                    <Button variant="default" onClick={() => setAction([])}>
                      Болих
                    </Button>
                    <Button loading={loading} type="submit">
                      Хадгалах
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Paper>
          </FormLayout>
        );
      }}
    </Form>
  );
}

const useHeader = ({ businessStatus }: { businessStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.regNumber ?? "-"}</Text>,
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <div>
          <Text size="sm" w="max-content">
            {record?.partner?.businessName ?? "-"}
          </Text>
          <Text c="indigo" size="sm">
            #{record?.partner?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <div>
        <Text w={"max-content"} size="sm">
          {record?.profileName ?? "-"}
        </Text>
        <Text c="indigo" size="sm">
          #{record?.refCode ?? "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (!!record?.branch?.name)
        return (
          <div>
            <Text size="sm" w="max-content">
              {record?.branch?.name ?? "-"}
            </Text>
            <Text c="indigo" size="sm">
              #{record?.branch?.refCode ?? "-"}
            </Text>
          </div>
        );
      else return "-";
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    render: (record) =>
      record?.classificationCategory ? (
        <div>
          <Text size="sm">{record?.classificationCategory?.name ?? "-"}</Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.classificationCategory?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Статус",
    dataIndex: "name",
    render: (record) => (
      <Badge
        color={businessStatus.find((item: IReference) => item.code === record.businessStatus)?.color ?? "indigo"}
        variant="light"
        styles={{
          label: {
            overflow: "visible",
            textOverflow: "unset",
          },
        }}>
        {businessStatus.find((item: IReference) => item.code === record.businessStatus)?.name ?? "-"}
      </Badge>
    ),
  },
  {
    title: "Бүсийн нэр",
    dataIndex: "name",
    render: (record) =>
      record?.areaRegion ? (
        <div>
          <Text size="sm">{record?.areaRegion?.name ?? "-"}</Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.areaRegion?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Чиглэлийн нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => (
      <div>
        <Text size="sm">{record?.areaDirection?.name ?? "-"}</Text>
        {record?.areaDirection?.refCode && (
          <Text c="indigo" size="sm">
            #{record?.areaDirection?.refCode ?? "-"}
          </Text>
        )}
      </div>
    ),
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.areaDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Хариуцсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaff?.firstName ? (
        <Flex gap={10} align="center" w={"max-content"}>
          <Avatar src={record?.businessStaff?.avatar} />
          <Text size="sm">
            {record?.businessStaff?.lastName ? record?.businessStaff?.lastName[0] + "." : ""} {record?.businessStaff?.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Орлох ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.businessStaff2 && record?.businessStaff2?.firstName)
        return (
          <Flex gap={10} align="center" w={"max-content"}>
            <Avatar src={record?.businessStaff2?.avatar} />
            <Text size="sm">
              {record?.businessStaff2?.lastName ? record?.businessStaff2?.lastName[0] + "." : ""} {record?.businessStaff2?.firstName}
            </Text>
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Ангилал тохируулсан огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.classificationSetDate ? dateTimeFormat(record.classificationSetDate) : "-"}</Text>,
  },
  {
    title: "Бүс тохируулсан огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.areaSetDate ? dateTimeFormat(record.areaSetDate) : "-"}</Text>,
  },
  {
    title: "Хуваарилсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.areaSetUser?.firstName ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.areaSetUser?.avatar} />
          <Text tt={"capitalize"} size="sm" w="max-content">
            {record?.areaSetUser?.lastName && record?.areaSetUser?.lastName[0] + "."} {record?.areaSetUser?.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
];
