import { Button, CloseButton, Grid, Group, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { PaymentTermApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  condition: yup.string().required("Заавал бөглөнө!").nullable(),
  configType: yup.string().required("Заавал бөглөнө!").nullable(),
  expireDayCount: yup.number().min(1, "Багадаа 1 байна").max(360, "Ихдээ 360 байна").max(360, "Ихдээ 360 байна").typeError("Заавал бөглөнө!").nullable(),
  month: yup.number().min(1, "Багадаа 1 байна").max(12, "Ихдээ 12 байна").typeError("Заавал бөглөнө!").nullable(),
  paymentDay: yup.number().max(31, "Ихдээ 31 байна").typeError("Заавал бөглөнө!").nullable(),
});

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  value: any;
}

type IFormData = {
  configType: string;
  condition: string;
  expireDayCount: string;
  month: number;
  paymentDay: number;
};

type IFormProps = {
  action: any[];
  setAction: (e: any) => void;
  title: string;
  subTitle: string;
  reload?: any;
};

export function DirectoryTermForm({ action, setAction, title, subTitle, reload }: IFormProps) {
  const { paymentTermConfigTypes = [], paymentTermConditions = [] } = useSelector((state: { general: IGeneral }) => state.general);

  const [loading, setLoading] = useState<boolean>(false);

  const [data] = useState<IFormData | any>({
    id: undefined,
    configType: undefined,
    condition: undefined,
    expireDayCount: undefined,
    month: undefined,
    paymentDay: undefined,
    ...(action[0] === "edit" && action[1]),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    let now = new Date();
    try {
      if (action[0] === "edit") {
        await PaymentTermApi.update(values.id, {
          configType: values.configType,
          condition: values.condition,
          expireDayCount: values.expireDayCount,
          month: values.month,
          paymentDay: values.paymentDay,
        });
      } else {
        await PaymentTermApi.create({
          configType: values.configType,
          condition: values.condition,
          expireDayCount: values.expireDayCount,
          month: values.month,
          paymentDay: values.paymentDay,
        });
      }
      message.success(`Амжилттай хадгалагдлаа. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`);
      setAction([]);
      reload();
    } catch (error: any) {
      message.error(error.message ?? "Хүсэлт амжилтгүй.");
    }
    setLoading(false);
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, value, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {value}
          </Text>
        </div>
      </Group>
    </div>
  ));

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setAction && setAction([])} />]}>
            <Paper>
              <Grid>
                <Grid.Col span={6}>
                  <SelectField
                    name="condition"
                    required
                    placeholder="Нөхцөл сонгох"
                    label="Нөхцөл"
                    clearable
                    options={paymentTermConditions.map((item: IReference) => {
                      return {
                        label: item.name,
                        value: item.code,
                      };
                    })}
                  />
                  asdasd
                  <pre>{JSON.stringify(paymentTermConditions, null, 2)}</pre>
                </Grid.Col>
                <Grid.Col span={6}>
                  <SelectField
                    searchable
                    name="configType"
                    required
                    placeholder="Нөхцөл код / тайлбар"
                    label="Нөхцөл код / тайлбар"
                    clearable
                    onChange={(e) => {
                      setFieldValue("month", undefined);
                      setFieldValue("paymentDay", undefined);
                      setFieldValue("expireDayCount", undefined);
                    }}
                    options={
                      paymentTermConfigTypes
                        ?.filter((item: any) => item?.condition === values?.condition)
                        ?.map((item: IReference) => {
                          return {
                            label: item?.text ?? "",
                            value: item?.code ?? "",
                          };
                        }) ?? []
                    }
                    disabled={!values?.condition}
                    itemComponent={SelectItem}
                    filter={(value: any, item: any) =>
                      item?.label?.toLowerCase().includes(value?.toLowerCase().trim()) ?? item?.configType?.toLowerCase().includes(value?.toLowerCase().trim())
                    }
                  />
                </Grid.Col>
                {(values?.configType === "INV_MF_X" || values?.configType === "INV_SOM") && (
                  <Grid.Col span={6}>
                    <TextField
                      disabled={values?.configType === "INV_SOM"}
                      label={"Сар"}
                      name="month"
                      placeholder={values?.configType === "INV_SOM" ? "Авто бодогдоно" : "Сар"}
                      type="number"
                      required
                    />
                  </Grid.Col>
                )}
                {values?.configType === "INV_NET_X" && (
                  <Grid.Col span={6}>
                    <TextField
                      disabled={values?.configType === "INV_SOM"}
                      label="Хоног"
                      name="expireDayCount"
                      placeholder={values?.configType === "INV_SOM" ? "Авто бодогдоно" : "Хоног"}
                      type="number"
                      required
                    />
                  </Grid.Col>
                )}
                {(values?.configType === "INV_SOM" || values?.configType === "INV_MF_X") && (
                  <Grid.Col span={6}>
                    <TextField disabled={values?.configType === "INV_SOM"} label="Өдөр" name="paymentDay" placeholder="Өдөр" type="number" required />
                  </Grid.Col>
                )}
                <Grid.Col span={12}>
                  <Group justify="right">
                    <Button loading={loading} type="submit">
                      Хадгалах
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Paper>
          </FormLayout>
        );
      }}
    </Form>
  );
}
