import { Avatar, Button, CloseButton, Divider, Flex, Grid, Group, Paper, Space, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { NetworkSetApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  businessIds: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  classificationCategoryId: yup.string().required("Заавал бөглөнө!").nullable(),
  classificationPriorityId: yup.string().required("Заавал бөглөнө!").nullable(),
  classificationDesc: yup.string().nullable().max(255, "хэт урт байна.").optional(),
});

type IFormData = {
  businessIds: string[];
  classificationCategoryId: string | undefined;
  classificationPriorityId: string | undefined;
  classificationDesc: string | undefined;
};

type IFormProps = {
  action: any[];
  setAction: (e: any) => void;
  title: string;
  subTitle: string;
  reload?: any;
};

export function RankForm({ action, setAction, title, subTitle, reload }: IFormProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { clientClassifications } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = useState<IFormData>({
    classificationCategoryId: undefined,
    classificationPriorityId: undefined,
    classificationDesc: undefined,
    businessIds:
      action[1]?.map((item: any) => {
        return item.id;
      }) ?? [],
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    let now = new Date();
    try {
      await NetworkSetApi.client_classification({
        businessIds: values?.businessIds ?? [],
        classificationCategoryId: values?.classificationCategoryId,
        classificationPriorityId: values?.classificationPriorityId,
        classificationDesc: values?.classificationDesc,
      });
      message.success(`Амжилттай хадгалагдлаа. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`);
      reload();
      setAction([]);
    } catch (error: any) {
      message.error(error.message ?? "Хүсэлт амжилтгүй.");
    }
    setLoading(false);
  };

  const columns = useHeader();

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setAction && setAction([])} />]}>
            <Paper>
              <Divider mb={"lg"} />
              <Grid>
                <Grid.Col span={12}>
                  <Text c={"dimmed"}>Харилцагчийн ангилал, зэрэглэл тохируулах</Text>
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6 }}>
                  <SelectField
                    onChange={() => {
                      setFieldValue("classificationPriorityId", undefined);
                    }}
                    name="classificationCategoryId"
                    required
                    label="Ангилал сонгох"
                    placeholder="Сонгоно уу"
                    clearable
                    options={
                      clientClassifications
                        ?.filter((item: { isParent: boolean }) => item.isParent)
                        ?.map((item: { name: string; id: string }) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) ?? []
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6 }}>
                  <SelectField
                    disabled={!values.classificationCategoryId}
                    name="classificationPriorityId"
                    required
                    label="Зэрэглэл сонгох"
                    placeholder="Сонгоно уу"
                    clearable
                    options={
                      clientClassifications
                        ?.filter((item: { parentId: string }) => values?.classificationCategoryId === item?.parentId)
                        ?.map((item: { name: string; id: string }) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        }) ?? []
                    }
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="classificationDesc" label="Тайлбар" placeholder="Тайлбар бичнэ үү" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text c={"dimmed"}>Сонгосон бизнесүүд</Text>
                  <Space h={"xs"} />
                  <Table pagination={false} name="rank.category.list" columns={columns} filters={{}} dataSource={(action[1] && action[1]) ?? []} />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Group justify="right">
                    <Button variant="default" onClick={() => setAction([])}>
                      Болих
                    </Button>
                    <Button loading={loading} type="submit">
                      Хадгалах
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Paper>
          </FormLayout>
        );
      }}
    </Form>
  );
}

const useHeader = (): ColumnType<any>[] => [
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.regNumber ?? "-"}</Text>,
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.profileName ?? "-"}
        </Text>
        <Text c="indigo" size="sm">
          #{record?.refCode ?? "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <div>
          <Text size="sm">{record?.partner?.businessName ?? "-"}</Text>
          <Text c="indigo" size="sm">
            #{record?.partner?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (!!record?.branch?.name)
        return (
          <div>
            <Text size="sm">{record?.branch?.name ?? "-"}</Text>
            <Text c="indigo" size="sm">
              #{record?.branch?.refCode ?? "-"}
            </Text>
          </div>
        );
      return "-";
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    width: "200px",
    render: (record) =>
      record?.classificationCategory ? (
        <div>
          <Text size="sm">{record?.classificationCategory?.name ?? "-"}</Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.classificationCategory?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    dataIndex: "name",
    width: "200px",
    render: (record) =>
      record?.classificationPriority ? (
        <div>
          <Text size="sm">{record?.classificationPriority?.name ?? "-"}</Text>
          <Text c="indigo" size="sm">
            {record?.classificationPriority?.description ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    render: (record) => <PopoverText>{record?.classificationDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Тохиргоо хийсэн",
    dataIndex: "name",
    render: (record) => {
      if (record?.businessStaffSetUser?.firstName) {
        return (
          <Flex gap={10} align="center">
            <Avatar src={record?.businessStaffSetUser?.avatar} />
            <Text size="sm" w="max-content">
              {record?.businessStaffSetUser?.lastName ? record?.businessStaffSetUser?.lastName[0] + "." : ""} {record?.businessStaffSetUser?.firstName}
            </Text>
          </Flex>
        );
      } else return "-";
    },
  },
  {
    title: "Өөрчлөлт хийсэн огноо, цаг",
    dataIndex: "name",
    render: (record) => <Text size="sm">{record?.classificationSetDate ? dateTimeFormat(record.classificationSetDate) : "-"}</Text>,
  },
];
