import { Avatar, Badge, Button, Divider, Flex, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { PaymentTermApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPaymentTermItem } from "../../interfaces/IPaymentTermItem";
import { IReference } from "../../models/General";
import { PageLayout } from "../layout";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, ITableRef, Table } from "../table";

export function ArNet30List() {
  const ref = React.useRef<ITableRef>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [action, setAction] = React.useState<string[]>([]);
  const params = useParams();
  const { paymentTermConditions } = useSelector((state: { general: IGeneral }) => state.general);

  const [filters] = React.useState({
    query: "",
    configType: "",
    condition: `${params.type}`,
  });

  const columns = useHeader({
    paymentTermConditions,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
      }
    },
  });

  return (
    <>
      <PageLayout
        title={`${paymentTermConditions.find((item: any) => item.code === params.type)?.name}`}
        subTitle={`${paymentTermConditions.find((item: any) => item.code === params.type)?.name} жагсаалт`}>
        <Divider mb="sm" />
        <Table ref={ref} name="client.priority.list" columns={columns} filters={filters} loadData={(data) => PaymentTermApi.list(data!)} pagination={false} />
      </PageLayout>
    </>
  );
}

const useHeader = ({
  onClick,
  paymentTermConditions,
}: {
  onClick: (key: string, record: IPaymentTermItem) => void;
  paymentTermConditions: IReference[];
}): ColumnType<IPaymentTermItem>[] => [
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)} color="grape">
              <IconInfoSquare />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
    width: "50px",
  },
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Нөхцөл",
    dataIndex: "condition",
    width: "200px",
    render: (record) => <Text size="sm">{paymentTermConditions.find((f: any) => f.code === record.condition)?.name ?? "-"}</Text>,
  },
  {
    title: "Код",
    width: "200px",
    dataIndex: "refCode",
    render: (record) => <Text size="sm">{record?.refCode ?? "-"}</Text>,
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    width: "420px",
    render: (record) => (
      <Text size="sm">
        <PopoverText>{record?.description ?? "-"}</PopoverText>
      </Text>
    ),
  },
  {
    title: "Хоног",
    width: "100px",
    dataIndex: "expireDayCount",
    render: (record) => <Text size="sm">{record?.expireDayCount ?? "-"}</Text>,
  },
  {
    title: "Сар",
    width: "100px",
    dataIndex: "month",
    render: (record) => <Text size="sm">{record?.month ?? "-"}</Text>,
  },
  {
    title: "Өдөр",
    dataIndex: "paymentDay",
    render: (record) => <Text size="sm">{record?.paymentDay ?? "-"}</Text>,
  },
  {
    title: "Статус",
    dataIndex: "isActive",
    width: "120px",
    render: (record) => (
      <Badge variant="light" color={record.isActive ? "green" : "gray"}>
        {record?.isActive ? "Идэвхтэй" : "Идэвхгүй"}
      </Badge>
    ),
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "modifiedUser",
    width: "320px",
    render: (record) => (
      <Flex gap={10} align="center">
        <Avatar src={record.modifiedUser.avatar ?? "/"} />
        <div>
          <Text size="sm" w="max-content">
            {record?.modifiedUser?.lastName} {record?.modifiedUser?.firstName}
          </Text>
          <Text c="dimmed" size="sm">
            {record?.modifiedUser?.email}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Default эсэх",
    dataIndex: "isMain",
    render: (record) => (
      <Badge variant="light" color={record.isMain ? "primay" : "gray"}>
        {record?.isMain ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "DeHUB стандарт",
    dataIndex: "isOpen",
    render: (record) => (
      <Badge variant="light" color={record.isOpen ? "primay" : "gray"}>
        {record?.isOpen ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
];
