import { ActionIcon, Badge, Divider, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconEdit } from "@tabler/icons-react";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { ReferenceApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { ColumnType, Table } from "../../components/table";
import { IClassification } from "../../interfaces/IClassification";
import { dateTimeFormat } from "../../utils/date";

export function ReferencesList() {
  const breadcrumbs = useBreadcrumb();
  const tableRef = useRef<any>(null);
  const { classes } = useStyles();
  const [filters] = React.useState({
    query: "",
  });

  const columns = useHeader({
    classes,
    onClick: (key) => {
      switch (key) {
        case "detail": {
          break;
        }
      }
    },
  });

  return (
    <PageLayout title="Лавлах мэдээлэл" subTitle="Network удирдлагын лавлах мэдээлэл" breadcrumb={breadcrumbs}>
      <Divider mb="sm" />
      <Table
        ref={tableRef}
        name="classification.list"
        columns={columns}
        filters={filters}
        loadData={async (data) => {
          let res = await ReferenceApi.list(data!);
          return res;
        }}
      />
      <Divider />
    </PageLayout>
  );
}

type HeaderProps = {
  classes: { [key: string]: string };
  onClick: (key: string, record: IClassification) => void;
};

const useHeader = ({ classes, onClick }: HeaderProps): ColumnType<IClassification>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "ref",
    render: (record) => (
      <Link to={`/reference/${record?.listType}`}>
        <ActionIcon variant="light">
          <IconEdit />
        </ActionIcon>
      </Link>
    ),
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Badge color="grape" radius="sm" variant="outline">
          {record?.refCode}
        </Badge>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    width: 240,
    render: (record) => {
      return (
        <Link to={`/reference/${record?.listType}`}>
          <Badge radius="sm" color="grape.4" className={classes.reference}>
            {record?.name}
          </Badge>
        </Link>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "ref",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.description ?? "-"}
      </Text>
    ),
  },
  {
    title: "Бүртгэсэн огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {dateTimeFormat(record.createdAt)}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Лавлах мэдээлэл",
  },
];

const useStyles = createStyles((theme) => ({
  reference: {
    "&:hover": {
      background: theme.colors.grape[7],
      cursor: "pointer",
    },
  },
}));
