import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const account = async (data: any) => {
  return httpRequest.put("/biz/network/set/account", data);
};

export const client_classification = async (data: any) => {
  return httpRequest.put("/biz/network/set/client_classification", data);
};

export const client_staff = async (data: any) => {
  return httpRequest.put("/biz/network/set/client_staff", data);
};

export const distribution_area = async (data: any) => {
  return httpRequest.put("/biz/network/set/distribution_area", data);
};

export const payment_term = async (data: any) => {
  return httpRequest.put("/biz/network/set/payment_term", data);
};
