import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";

const initialState: IGeneral = {
  branchStatus: [],
  branchTypes: [],
  businessStaffRoles: [],
  businessStatus: [],
  businessTypes: [],
  contractDocTypes: [],
  contractStatus: [],
  invitationStatus: [],
  invitationTypes: [],
  partnerClassifications: [],
  partnerRegisterStatus: [],
  partnerTypes: [],
  productCategoryTypes: [],
  purchaseTypes: [],
  serviceCategoryTypes: [],
  districts: [],
  equityTypes: [],
  khoroos: [],
  legalEntityTypes: [],
  partnerCategories: [],
  provinces: [],
  paymentTermConditions: [],
  paymentTermConfigTypes: [],
  directoryTitle: "",
  directoryDescription: "",
  bankAccounts: [],
  clientClassifications: [],
  distributionAreas: [],
  staffs: [],
  zipCodes: [],
  referenceTypes: [],
  referenceListTypes: [],
  menus: [],
  permissions: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        branchStatus,
        branchTypes,
        businessStaffRoles,
        businessStatus,
        businessTypes,
        contractDocTypes,
        contractStatus,
        invitationStatus,
        invitationTypes,
        partnerClassifications,
        partnerRegisterStatus,
        partnerTypes,
        productCategoryTypes,
        purchaseTypes,
        serviceCategoryTypes,
        districts,
        equityTypes,
        khoroos,
        legalEntityTypes,
        partnerCategories,
        provinces,
        paymentTermConditions,
        paymentTermConfigTypes,
        bankAccounts,
        clientClassifications,
        distributionAreas,
        staffs,
        zipCodes,
        referenceTypes,
        referenceListTypes,
        menus,
        permissions,
      } = payload;

      state.branchStatus = branchStatus;
      state.branchTypes = branchTypes;
      state.businessStaffRoles = businessStaffRoles;
      state.businessStatus = businessStatus.map((item: any) => {
        if (item.code === "ACTIVE") {
          return {
            ...item,
            color: "green",
          };
        } else if (item.code === "INACTIVE") {
          return {
            ...item,
            color: "red",
          };
        } else if (item.code === "DRAFT") {
          return {
            ...item,
            color: "gray",
          };
        } else
          return {
            ...item,
            color: "primary",
          };
      });
      state.businessTypes = businessTypes;
      state.contractDocTypes = contractDocTypes;
      state.contractStatus = contractStatus;
      state.invitationStatus = invitationStatus;
      state.invitationTypes = invitationTypes;
      state.partnerClassifications = partnerClassifications;
      state.partnerRegisterStatus = partnerRegisterStatus;
      state.partnerTypes = partnerTypes;
      state.productCategoryTypes = productCategoryTypes;
      state.purchaseTypes = purchaseTypes;
      state.serviceCategoryTypes = serviceCategoryTypes;
      state.referenceListTypes = referenceListTypes;

      state.districts = districts;
      state.equityTypes = equityTypes;
      state.khoroos = khoroos;
      state.legalEntityTypes = legalEntityTypes;
      state.partnerCategories = partnerCategories;
      state.provinces = provinces;

      state.paymentTermConditions = paymentTermConditions.filter((f) => f.code !== "AR_NET30");
      state.paymentTermConfigTypes = paymentTermConfigTypes;
      state.bankAccounts = bankAccounts;
      state.clientClassifications = clientClassifications;
      state.distributionAreas = distributionAreas;
      state.staffs = staffs;
      state.zipCodes = zipCodes;
      state.referenceTypes = referenceTypes;
      state.permissions = permissions;
      state.menus = menus?.filter((item) => item.module === "NETWORK");
      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral } = generalSlice.actions;
