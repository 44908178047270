import { Button, Divider, Grid, LoadingOverlay, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { NetworkInvitationApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  type: yup.string().required("Заавал бөглөнө!").nullable(),
  legalEntityType: yup.string().required("Заавал бөглөнө!").nullable(),
  equityType: yup.string().required("Заавал бөглөнө!").nullable(),
  partnerCategoryId: yup.string().required("Заавал бөглөнө!"),
  country: yup.string().required("Заавал бөглөнө!"),
  classification: yup.string().required("Заавал бөглөнө!"),
  logo: yup.string().nullable(),
  businessName: yup
    .string()
    .required("Заавал бөглөнө!")
    .matches(/^[А-Яа-я|Үү|Өө|Ёё| |\\s]{2,255}$/, "Зөвхөн крилл үсэг оруулна уу.")
    .max(255, "Хэт урт байна.")
    .trim(),
  businessNameEng: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна."),
  stateRegDate: yup.string().required("Заавал бөглөнө!"),
  regNumber: yup
    .string()
    .when("type", (type, schema) => {
      return typeof type === "string" && type === "COMPANY"
        ? schema.matches(/^[0-9]{7}$/, "Заавал бөглөнө!").required("Заавал бөглөнө!")
        : schema.notRequired();
    })
    .when("type", (type, schema) => {
      return typeof type === "string" && type === "CITIZEN"
        ? schema.matches(/^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Заавал бөглөнө!").required("Заавал бөглөнө!")
        : schema.notRequired();
    })
    .nullable()
    .required("Заавал бөглөнө!")
    .trim(),
  web: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      "Вэб url биш байна!",
    )
    .trim()
    .nullable(),
  email: yup.string().email("Цахим шуудан байна!").required("Заавал бөглөнө!").trim(),
  email2: yup.string().email("Цахим шуудан байна!").nullable(),
  phone: yup
    .number()
    .typeError("Зөвхөн дугаар оруулна уу")
    .transform((value) => (value ? value : null))
    .required("Заавал бөглөнө!"),
  phone2: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Зөвхөн дугаар оруулна уу!")
    .nullable(),
  fbLink: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^http(s*):\/\/(www.)*facebook\.com\/[a-zA-Z0-9.]+$/i, "Facebook url биш байна!")
    .nullable(),
  province: yup.string().required("Заавал бөглөнө!"),
  district: yup.string().required("Заавал бөглөнө!"),
  khoroo: yup.string().required("Заавал бөглөнө!"),
  khotkhonBair: yup.string().required("Заавал бөглөнө!"),
  khoroolol: yup.string().required("Заавал бөглөнө!"),
  khashaaDavkhar: yup.string().required("Заавал бөглөнө!"),
  khaalgaDugaar: yup.string().required("Заавал бөглөнө!"),
  businessAddress: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!"),
  locationLat: yup.number().typeError("Тоогоор оруулна уу").required("Заавал бөглөнө!"),
  locationLng: yup.number().typeError("Тоогоор оруулна уу").required("Заавал бөглөнө!"),
  firstName: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
  userPhone: yup.string().required("Заавал бөглөнө!").nullable(),
  userEmail: yup.string().required("Заавал бөглөнө!").nullable(),
  toMessage: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
});

type IFormData = {
  type: string | undefined | null;
  regNumber: string | undefined | null;
  businessName: string | undefined | null;
  businessNameEng: string | undefined | null;
  legalEntityType: string | undefined | null;
  country: string | undefined | null;
  equityType: string | undefined | null;
  web: string | undefined | null;
  email: string | undefined | null;
  email2: string | undefined | null;
  phone: string | undefined | null;
  phone2: string | undefined | null;
  fbLink: string | undefined | null;
  province: string | undefined | null;
  district: string | undefined | null;
  khoroo: string | undefined | null;
  khotkhonBair: string | undefined | null;
  khoroolol: string | undefined | null;
  khashaaDavkhar: string | undefined | null;
  khaalgaDugaar: string | undefined | null;
  businessAddress: string | undefined | null;
  locationLat: number | undefined | null;
  locationLng: number | undefined | null;
  firstName: string | undefined | null;
  userPhone: string | undefined | null;
  userEmail: string | undefined | null;
  toMessage: string | undefined | null;
};

export function OnBoardingForm() {
  const { businessType } = useSelector((state: any) => state.auth);
  const { equityTypes = [], legalEntityTypes = [], zipCodes = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { classes } = useStyles();
  const { partnerTypes } = useSelector((state: any) => state.general);
  const [data] = React.useState<IFormData | any>({
    type: undefined,
    businessName: undefined,
    businessNameEng: undefined,
    legalEntityType: undefined,
    country: undefined,
    equityType: undefined,
    isVatPayer: false,
    web: undefined,
    email: undefined,
    email2: undefined,
    phone: undefined,
    phone2: undefined,
    fbLink: undefined,
    province: undefined,
    district: undefined,
    khoroo: undefined,
    khotkhonBair: undefined,
    khoroolol: undefined,
    khashaaDavkhar: undefined,
    khaalgaDugaar: undefined,
    businessAddress: undefined,
    locationLat: 47.908676264556966,
    locationLng: 106.87936663627625,
    firstName: undefined,
    userPhone: undefined,
    userEmail: undefined,
    toMessage: undefined,
    regNumber: undefined,
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      const valid = {
        type: values?.type ?? null,
        businessName: values?.businessName ?? null,
        businessNameEng: values?.businessNameEng ?? null,
        legalEntityType: values?.legalEntityType ?? null,
        country: values?.country ?? null,
        equityType: values?.equityType ?? null,
        web: values?.web ?? null,
        email: values?.email ?? null,
        email2: values?.email2 ?? null,
        phone: values?.phone ?? null,
        phone2: values?.phone2 ?? null,
        fbLink: values?.fbLink ?? null,
        province: values?.province ?? null,
        district: values?.district ?? null,
        khoroo: values?.khoroo ?? null,
        khotkhonBair: values?.khotkhonBair ?? null,
        khoroolol: values?.khoroolol ?? null,
        khashaaDavkhar: values?.khashaaDavkhar ?? null,
        khaalgaDugaar: values?.khaalgaDugaar ?? null,
        businessAddress: values?.businessAddress ?? null,
        locationLat: values?.locationLat ?? null,
        locationLng: values?.locationLng ?? null,
        firstName: values?.firstName ?? null,
        userPhone: values?.userPhone ?? null,
        userEmail: values?.userEmail ?? null,
        toMessage: values?.toMessage ?? null,
        regNumber: values?.regNumber ?? null,
      };
      await NetworkInvitationApi.onboarding(valid);
      message.success("Урилга амжилтай илгээгдлээ.");
      navigate("/sent");
    } catch (error: any) {
      message.error(error?.message ?? "Хүсэлт амжилтгүй боллоо.");
    }
    setLoading(false);
  };

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values, setFieldValue }) => {
          return (
            <FormLayout
              title="ҮНДСЭН МЭДЭЭЛЭЛ"
              subTitle={`Та ${
                businessType !== "SUPPLIER" ? "нийлүүлэгчийн" : "худалдан авагчийн"
              } үндсэн мэдээллийг өөрийн хамтран ажилладаг гэрээний дагуу бүртгээд урина уу.`}
              extra={[
                <Link to={"/sent"} key={0}>
                  <Button variant="default">Буцах</Button>
                </Link>,
                <Button loading={loading} type="submit" key={1}>
                  Илгээх
                </Button>,
              ]}>
              <Divider mb="lg" />
              <LoadingOverlay visible={loading} />
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    clearable
                    name="type"
                    label="Төрөл:"
                    placeholder="Төрөл"
                    onChange={() => {
                      setFieldValue("regNumber", undefined);
                    }}
                    options={partnerTypes?.map((t: any) => ({ label: t.name, value: t.code })) ?? []}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  {values.type === "COMPANY" ? (
                    <TextField disabled={!values.type} type="number" name="regNumber" label="Татвар төлөгчийн дугаар:" placeholder="Татвар төлөгчийн дугаар" />
                  ) : (
                    <TextField disabled={!values.type} name="regNumber" label="Регистрийн дугаар:" placeholder="Регистрийн дугаар" required />
                  )}
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="businessName" label="Аж ахуйн нэгжийн нэр:" placeholder="Аж ахуйн нэгжийн нэр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="businessNameEng" label="Аж ахуйн нэгжийн нэр/Латин/:" placeholder="Аж ахуйн нэгжийн нэр/Латин/" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    searchable
                    clearable
                    options={legalEntityTypes?.map((c: any, index: number) => ({ label: c.name, value: c.code })) ?? []}
                    label="Аж ахуйн нэгжийн хэлбэр"
                    name="legalEntityType"
                    placeholder="Аж ахуйн нэгжийн хэлбэр"
                    required
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField options={[{ label: "Монгол", value: "Монгол" }]} label="Улсын харьяалал" name="country" placeholder="Улсын харьяалал" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    options={equityTypes?.map((c: any, index: number) => ({ label: c.name, value: c.code })) ?? []}
                    label="Өмчийн хэлбэр"
                    name="equityType"
                    placeholder="Өмчийн хэлбэр"
                    required
                  />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={12}>
                  <Text size={"md"} fz="lg" fw={600}>
                    ХОЛБОО БАРИХ
                  </Text>
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="web" label="Веб сайтын хаяг:" placeholder="Веб сайтын хаяг" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="email" type="email" label="И-мэйл хаяг №1:" placeholder="И-мэйл хаяг №1" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="email2" label="И-мэйл хаяг №2:" placeholder="И-мэйл хаяг №2" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="phone" label="Утасны дугаар №1:" placeholder="Утасны дугаар №1" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="phone2" label="Утасны дугаар №2:" placeholder="Утасны дугаар №2" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="fbLink" label="Facebook хаяг:" placeholder="Facebook хаяг" />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <Text size={"md"} fz="lg" fw={600}>
                    ХАЯГИЙН МЭДЭЭЛЭЛ
                  </Text>
                </Grid.Col>
              </Grid>
              <Divider my="lg" />

              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    onChange={() => {
                      setFieldValue("district", undefined);
                      setFieldValue("khoroo", undefined);
                    }}
                    options={zipCodes?.filter((item: any) => item.parent === "0")?.map((c: any, index: number) => ({ label: c.name, value: c.code })) ?? []}
                    label="Аймаг, нийслэл"
                    name="province"
                    placeholder="Аймаг, нийслэл"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    onChange={() => {
                      setFieldValue("khoroo", undefined);
                    }}
                    options={
                      zipCodes?.filter((item: any) => item.parent === values.province)?.map((c: any, index: number) => ({ label: c.name, value: c.code })) ?? []
                    }
                    label="Сум, дүүрэг"
                    name="district"
                    placeholder="Сум, дүүрэг"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    options={
                      zipCodes?.filter((item: any) => item.parent === values.district)?.map((c: any, index: number) => ({ label: c.name, value: c.code })) ?? []
                    }
                    label="Баг, хороо"
                    name="khoroo"
                    placeholder="Баг, хороо"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="khoroolol" label="Хороолол, гудамж:" placeholder="Хороолол, гудамж" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="khotkhonBair" label="Хотхон, байр:" placeholder="Хотхон, байр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="khashaaDavkhar" label="Хашаа, давхар:" placeholder="Хашаа, давхар" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="khaalgaDugaar" label="Хаалганы дугаар:" placeholder="Хаалганы дугаар" required />
                </Grid.Col>
                <Grid.Col span={8}>
                  <TextareaField name="businessAddress" label="хаягийн бичгийн тайлбар:" placeholder="хаягийн бичгийн тайлбар" required />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <Text size={"md"} fz="lg" fw={600}>
                    БАЙРШИЛ
                  </Text>
                  <Text size={"md"} fz="sm" fw={400} className={classes.text}>
                    Газрын зураг дээр сонгож тэмдэглэвэл автоматаар уртраг, өргөрөг хадгалагдана.
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Grid>
                    <Grid.Col span={12}>
                      <TextField label="Өргөрөг" name="locationLat" placeholder="Өргөрөг" required />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextField label="Уртраг" name="locationLng" placeholder="Уртраг" required />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
              <Divider my="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <Text size={"md"} fz="lg" fw={600}>
                    УРИЛГА АВАХ ХҮНИЙ МЭДЭЭЛЭЛ
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="firstName" label="Урилга авах хүний нэр:" placeholder="Урилга авах хүний нэр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="userPhone" label="Утасны дугаар №1:" placeholder="Утасны дугаар №1" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="userEmail" label="И-мэйл хаяг №1:" placeholder="И-мэйл хаяг №1" required />
                </Grid.Col>
                <Grid.Col span={8}>
                  <TextareaField name="toMessage" label="Урилгаар очих мэдээлэл:" placeholder="Урилгаар очих мэдээлэл" required max={255} />
                </Grid.Col>
              </Grid>
            </FormLayout>
          );
        }}
      </Form>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  text: {
    color: theme.colors.gray[6],
  },
  between: {
    display: "flex",
    gap: "8px",
  },
}));
