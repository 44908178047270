import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IconUserShield } from "@tabler/icons-react";
import { Alert } from "@mantine/core";

export type IPermissionCode =
  | "NET_DASH"
  | "NET_INV_REC"
  | "NET_INV_RES"
  | "NET_INV_SENT"
  | "NET_INV_ONB"
  | "NET_LIST"
  | "NET_PT"
  | "NET_PT_SET"
  | "NET_ACC"
  | "NET_STF"
  | "NET_STF_SET"
  | "NET_AREA_SET"
  | "NET_CLS_SET"
  | "NET_REF_AREA"
  | "NET_REF_CLS";

export function usePermission(code: IPermissionCode) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { permissions } = useSelector((state: { general: IGeneral }) => state.general);

  function accessDenied() {
    return (
      <Alert title={code && "Хандах эрх хүрэлцэхгүй байна!"} icon={<IconUserShield />} color="red">
        {permissions?.find((item: any) => item.code === code)?.name || "Хандах эрх хүрэлцэхгүй байна!"}
      </Alert>
    );
  }

  const hasAccess = user?.permissions?.some((som: any) => som.code === code) || false;
  const isCreate = user?.permissions?.some((item: any) => item.code === code && item.isCreate) || false;
  const isDelete = user?.permissions?.some((item: any) => item.code === code && item.isDelete) || false;
  const isEdit = user?.permissions?.some((item: any) => item.code === code && item.isEdit) || false;
  const isReview = user?.permissions?.some((item: any) => item.code === code && item.isReview) || false;
  const isView = user?.permissions?.some((item: any) => item.code === code && item.isView) || false;

  return {
    hasAccess,
    isCreate,
    isDelete,
    isEdit,
    isReview,
    isView,
    accessDenied,
  };
}
