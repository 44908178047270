import { Avatar, Button, Flex, Grid, Group, Modal, Paper, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useColorScheme } from "@mantine/hooks";
import { IconMail, IconMailForward, IconMapPin, IconPhoneCall, IconPhoto, IconStar, IconStarHalf, IconWorld } from "@tabler/icons-react";
import React, { useRef, useState } from "react";
import { NetworkInvitationApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IBusiness } from "../../interfaces/IBusiness";
import { InvitePinForm } from "./invite-pin-form";
import { InviteSupplierForm } from "./invite-supplier-form";

export function InviteSupplier() {
  const [action, setAction] = useState<any[]>([]);
  const refTable = useRef<any>(null);
  const { classes, theme } = useStyle();

  const columns = useHeader({
    onClick: (key, record) => {
      setAction([key, record]);
    },
  });

  const [filters] = React.useState({ query: "" });

  const collapseChildren = (record: any) => {
    return (
      <div className={classes.card}>
        <Grid>
          <Grid.Col span={3}>
            <Flex gap={20}>
              <Avatar src={record?.logo} size={50} radius="md">
                <IconPhoto />
              </Avatar>
              <Flex gap={4} direction="column">
                <Text size="lg" fw={500} className={classes.name}>
                  {record?.profileName ?? "-"}
                </Text>
                <Text size="xs" c="dimmed">
                  {record?.refCode}
                </Text>
                <Text size="xs" c="dimmed">
                  <Flex align={"center"}>
                    <IconStar stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    <IconStar stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    <IconStar stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    <IconStarHalf stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    (1223)
                  </Flex>
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
            {record?.partner.web && (
              <Group mt={5}>
                <IconWorld stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" c="dimmed">
                  {record?.partner?.web ?? "-"}
                </Text>
              </Group>
            )}
            {record?.partnerEmail && (
              <Group mt={3}>
                <IconMail stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" c="dimmed">
                  {record?.partnerEmail}
                </Text>
              </Group>
            )}
            {record?.partnerPhone && (
              <Group mt={3}>
                <IconPhoneCall stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" c="dimmed">
                  {record?.partnerPhone}
                </Text>
              </Group>
            )}
            {record?.partner?.businessAddress && (
              <Group mt={3}>
                <IconMapPin stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" c="dimmed">
                  {record?.partner?.businessAddress}
                </Text>
              </Group>
            )}
          </Grid.Col>
          <Grid.Col span={5}></Grid.Col>
        </Grid>
      </div>
    );
  };

  return (
    <Paper>
      <br />
      <Table
        ref={refTable}
        collapse
        collapseChildren={collapseChildren}
        name="supplier.network.invitation.business.list"
        columns={columns}
        filters={filters}
        loadData={(data) => NetworkInvitationApi.business_list(data!)}
      />

      <Modal withCloseButton={false} opened={action[0] === "invite"} onClose={() => setAction([])} size={"1200px"} centered>
        <InviteSupplierForm
          reload={async () => {
            setAction([]);
            await refTable.current.reload();
          }}
          action={action}
          setAction={setAction}
          subTitle="Нийлүүлэгчид илгээх урилга"
          title="Нийлүүлэгчид илгээх урилга"
        />
      </Modal>
      <Modal withCloseButton={false} opened={action[0] === "pin"} onClose={() => setAction([])} size={"md"} centered>
        <InvitePinForm
          reload={async () => {
            await refTable.current.reload();
          }}
          action={action}
          onCancel={setAction}
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
        />
      </Modal>
    </Paper>
  );
}

const useStyle = createStyles((theme) => ({
  content: {
    display: "flex",
    gap: "8px",
  },
  icon: {
    color: useColorScheme() === "dark" ? theme.colors.dark[3] : theme.colors.gray[5],
  },

  name: {},

  card: {
    width: "100%",
  },
}));

const useHeader = ({ onClick }: { onClick: (key: string, record: IBusiness) => void }): ColumnType<IBusiness>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Партнер #",
    render: (record) => (
      <Text c="primary" w={"max-content"}>
        {record?.partner?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Партнер нэр",
    render: (record) => <Text size="sm">{record?.partnerName ?? "-"}</Text>,
  },
  {
    title: "Supplier #",
    render: (record) => <Text size="sm">{record?.refCode ?? "-"}</Text>,
  },
  {
    title: "Supplier Бизнес",
    render: (record) => <Text size="sm">{record?.profileName ?? "-"}</Text>,
  },
  {
    title: "Supplier админ",
    render: (record) =>
      record?.staff ? (
        <Flex align="center" gap={10}>
          <Avatar src={record?.staff?.avatar} />
          <div>
            <Text size="sm">
              {record?.staff?.lastName ? record?.staff?.lastName[0] + "." : ""} {record?.staff?.firstName ?? "-"}
            </Text>
            <Text size="sm" c="dimmed">
              {record?.staff?.email ?? ""}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Үйлдэл",
    align: "center",
    width: "120px",
    render: (record) => {
      return (
        <Button onClick={(key) => onClick("invite", record)} size="xs" variant="subtle">
          <IconMailForward size={18} stroke={2} />
          <Space w={"xs"} /> Урих
        </Button>
      );
    },
  },
];
