import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const get = async (id: string) => {
  return httpRequest.get(`/biz/reference/${id}`);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/reference", { offset, filter });
};
