import { useParams } from "react-router";
import { PageLayout } from "../../components/layout";
import { ArNet30List } from "../../components/reference/ar-net30-list";
import { ClientCateoryList } from "../../components/reference/client-category";
import { ClientPriorityList } from "../../components/reference/client-priority";
import { CodTermList } from "../../components/reference/cod-term-list";
import { InvConfigTermList } from "../../components/reference/inv-config-term-list";
import { SalesDistributionList } from "../../components/reference/sales-direction";
import { SalesRegionList } from "../../components/reference/sales-region";

export function ReferenceType() {
  const params = useParams();

  const referenceListTypesConvert = [
    {
      code: "COD",
      name: "Бэлэн төлөх нөхцөл",
      render: () => <CodTermList />,
    },
    {
      code: "INV_CONFIG",
      name: "Нэхэмжлэхээр төлөх нөхцөл",
      render: () => <InvConfigTermList />,
    },
    {
      code: "AR_NET30",
      name: "Авлага",
      render: () => <ArNet30List />,
    },
    {
      code: "REGION",
      name: "Борлуулалтын бүс",
      render: () => <SalesRegionList />,
    },
    {
      code: "DIRECTION",
      name: "Борлуулалтын чиглэл",
      render: () => <SalesDistributionList />,
    },
    {
      code: "CLIENT_CATEGORY",
      name: "Харилцагчийн ангилал",
      render: () => <ClientCateoryList />,
    },
    {
      code: "CLIENT_PRIORITY",
      name: "Харилцагчийн зэрэглэл",
      render: () => <ClientPriorityList />,
    },
  ];

  const breadcrumbs = useBreadcrumb(referenceListTypesConvert.find((item: any) => item.code === params.type)?.name);

  return (
    <PageLayout title="" breadcrumb={breadcrumbs}>
      {referenceListTypesConvert.find((item: any) => item.code === params.type)?.render()}
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    to: "/reference",
    label: "Лавлах мэдээлэл",
  },
  { label: type },
];
