import { Anchor, Avatar, Flex, Group, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";

export const LastInvitationSentList = ({ sentInvitations }: { sentInvitations: any[] }) => {
  const refTable = useRef<ITableRef>(null);
  const { invitationStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ invitationStatus });

  return (
    <Table
      pagination={false}
      ref={refTable}
      name="last.invitation.sent.list"
      columns={columns}
      filters={{
        query: "",
        type: "NETWORK",
      }}
      dataSource={sentInvitations || []}
    />
  );
};

const useHeader = ({ invitationStatus }: { invitationStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "Урилга №",
    render: (record) => (
      <Anchor>
        <Text># {record?.refCode || "-"}</Text>
      </Anchor>
    ),
  },
  {
    title: "Статус",
    render: (record) =>
      Tag(
        invitationStatus?.find((item: any) => item.code === record.invitationStatus)?.color || "blue",
        invitationStatus?.find((item: any) => item.code === record.invitationStatus)?.name || "-",
      ),
  },
  {
    title: "Хүлээн авсан партнер",
    render: (record) =>
      record?.receiver?.partner ? (
        <Group>
          <Avatar src={record?.receiver?.partner?.logo}>
            <IconPhoto />
          </Avatar>
          <Flex gap={5} rowGap={0} direction="column" w="max-content">
            <Text w={"max-content"}>{record?.receiver?.partner?.businessName || "-"}</Text>
            <Text color="indigo"># {record?.receiver?.partner?.refCode || "-"}</Text>
          </Flex>
        </Group>
      ) : (
        "-"
      ),
  },
  {
    title: "Хүлээн авсан бизнес",
    render: (record) =>
      record?.receiver ? (
        <Group>
          <Avatar src={record?.receiver?.logo}>
            <IconPhoto />
          </Avatar>
          <Flex gap={5} rowGap={0} direction="column" w="max-content">
            <Text w={"max-content"}>{record?.receiver?.profileName || "-"}</Text>
            <Text color="indigo"># {record?.receiver?.refCode || "-"}</Text>
          </Flex>
        </Group>
      ) : (
        "-"
      ),
  },
  {
    title: "Илгээсэн огноо, цаг",
    render: (record) => <Text w="max-content">{dateTimeFormat(record.invitedDate)}</Text>,
  },
];
