import { Box, Popover, Text } from "@mantine/core";

export function PopoverText({
  children,
  withArrow = true,
  position = "top",
}: {
  children: string;
  withArrow?: boolean;
  position?: "top" | "left" | "bottom" | "right";
}) {
  if (children && children.length > 18)
    return (
      <Box w={"max-content"}>
        <Popover position={position} withArrow={withArrow} shadow="sm">
          <Popover.Target>
            <Text component="a" w={"max-conent"}>
              {children?.substring(0, 18) || ""}...
            </Text>
          </Popover.Target>
          <Popover.Dropdown h={"max-content"} maw={"350px"}>
            <Text size="sm" style={{ overflowWrap: "break-word" }}>
              {children}
            </Text>
          </Popover.Dropdown>
        </Popover>
      </Box>
    );
  else return <Text w={"max-content"}>{children}</Text>;
}
