import { Avatar, Checkbox, Group, LoadingOverlay, Text } from "@mantine/core";
import { NetworkInvitationApi } from "../../apis";
import { ColumnType, Table } from "../table";

type IType = {
  action: any[];
  values: any;
  setFieldValue: (e1: string, e2: any) => void;
};

export function InviteBuyerList({ action, setFieldValue, values }: IType) {
  const columns = useHeader({
    setFieldValue,
    values,
  });
  if (action[0] !== "invite") return <LoadingOverlay visible />;
  else
    return (
      <Table
        name={`buyer.receiverIds.${JSON.stringify((action[0] === "invite" && action[1]).partnerId)}`}
        columns={columns}
        filters={{ query: "", partnerId: (action[0] === "invite" && action[1]).partnerId }}
        loadData={(data) => NetworkInvitationApi.business_suggest(data!)}
      />
    );
}

const useHeader = ({ setFieldValue, values }: { values: any; setFieldValue: (e1: string, e2: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "",
    renderTitle: (data, index) => {
      return (
        <>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFieldValue(`receiverIds`, [...(data.rows?.map((ite: any) => ite.id) ?? [])]);
              } else {
                setFieldValue(`receiverIds`, []);
              }
            }}
          />
        </>
      );
    },
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {
            if (values?.receiverIds?.some((item: string) => item === record.id) ?? false) {
              setFieldValue(`receiverIds`, [...values?.receiverIds.filter((fil: string) => fil !== record.id)]);
            } else setFieldValue(`receiverIds`, [...(values?.receiverIds ?? []), record.id]);
          }}
          checked={values?.receiverIds?.some((item: string) => item === record.id) ?? false}
        />
      );
    },
  },
  {
    title: "Партнер нэр",
    render: (record) => (
      <Text w={"max-content"}>
        {record?.partnerName ?? "-"}, {record?.partner?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Buyer Бизнес",
    render: (record) => `${record?.profileName ?? "-"}, #${record?.refCode ?? "-"}`,
  },
  {
    title: "ТТД",
    render: (record) => `${record?.regNumber ?? "-"}`,
  },
  {
    title: "Buyer админ",
    render: (record) =>
      record?.staff ? (
        <Group w={"max-content"}>
          <Avatar size={40} src={record?.staff?.avatar} radius={40} />
          <div>
            <Text size="sm" fw={500} tt={"capitalize"}>
              {record?.staff?.lastName} {record?.staff?.firstName}
            </Text>
            <Text size="xs" c="dimmed">
              {record?.staff?.email}
            </Text>
          </div>
        </Group>
      ) : (
        "-"
      ),
  },
  {
    title: "Утасны дугаар",
    render: (record) => `${record?.staff?.phone ?? "-"}`,
  },
];
