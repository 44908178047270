import { Avatar, Checkbox, Divider, Flex, Group, Paper, Tabs, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NetworkBusinessApi } from "../../apis";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IBusiness } from "../../interfaces/IBusiness";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";

export function AccountManageList({
  values,
  setFieldValue,
  errors,
  tableRef,
}: {
  values: any;
  setFieldValue: any;
  errors: any;
  tableRef: React.Ref<ITableRef>;
}) {
  const { paymentTermConfigTypes, businessStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters, setFilters] = useState<any>({ query: "", setAccount: "true" });

  const columns = useHeader({
    businessStatus,
    businessTypes,
    paymentTermConfigTypes,
    onClick: (key, item) => {},
    setFieldValue,
    values,
  });

  return (
    <div>
      <Tabs
        onChange={(e: string | null) => {
          setFilters({ query: "", setAccount: e });
          setFieldValue("businessIds", []);
        }}
        variant="pills"
        value={`${filters.setAccount}`}>
        <Tabs.List>
          <Tabs.Tab value="true">Дансны тохиргоо хийх бизнес</Tabs.Tab>
          <Tabs.Tab value="false">Данс тохируулсан бизнес</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Divider label={`Сонгосон бизнесийн тоо ${values?.businessIds?.length ?? 0}`} mt={"md"} />
      <Table
        placeholder={"Танд дансны тохиргоо хийх шаардлагатай хамтрагч бизнес байхгүй байна"}
        ref={tableRef}
        name="network.invitation.received.supplier.invite"
        columns={columns}
        filters={filters}
        loadData={(data) => NetworkBusinessApi.list(data!)}
      />
    </div>
  );
}

const useHeader = ({
  paymentTermConfigTypes,
  businessStatus,
  businessTypes,
  onClick,
  values,
  setFieldValue,
}: {
  paymentTermConfigTypes: IReference[];
  businessStatus: IReference[];
  businessTypes: IReference[];
  values: any;
  onClick: (e: string, e2: any) => void;
  setFieldValue: any;
}): ColumnType<IBusiness>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "",
    renderTitle: (data: any) => {
      return (
        <>
          <Checkbox
            onChange={(e) => {
              if (!e.target.checked) {
                setFieldValue("businessIds", []);
              } else
                setFieldValue(
                  "businessIds",
                  data?.rows?.map((item: any) => item.id),
                );
            }}
            checked={values?.businessIds?.length > 0}
            onClick={() => {}}
          />
        </>
      );
    },
    render: (record) => {
      return (
        <Checkbox
          onClick={() => {
            if (values?.businessIds?.some((item: string) => item === record.id)) {
              setFieldValue("businessIds", [...(values?.businessIds?.filter((f: string) => f !== record.id) ?? [])]);
            } else setFieldValue("businessIds", [...(values?.businessIds ?? []), record.id]);
          }}
          onChange={() => {}}
          checked={values?.businessIds?.some((item: string) => item === record.id) ?? false}
        />
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.profileName ?? "-"}
        </Text>
        <Text c="indigo" size="sm">
          #{record?.refCode ?? "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <Group w="max-content" gap={10}>
          <Paper withBorder>
            <Avatar src={record?.partner?.logo} radius="xs">
              <IconPhoto />
            </Avatar>
          </Paper>
          <div>
            <Text size="sm" w="max-content">
              {record?.partner?.businessName ?? "-"}
            </Text>
            <Text c="indigo" size="sm">
              #{record?.partner?.refCode ?? "-"}
            </Text>
          </div>
        </Group>
      ) : (
        "-"
      ),
  },
  {
    title: "ТТД",
    render: (record) => <Text size="sm">{record?.partner?.regNumber ?? record?.regNumber ?? "-"}</Text>,
  },
  {
    title: "Орлого хүлээн авах данс",
    dataIndex: "inAcc",
    render: (record) =>
      record.inAcc ? (
        <Flex gap={10} align="center">
          <Paper withBorder>
            <Avatar src={record.inAcc.icon} radius="xs">
              <IconPhoto />
            </Avatar>
          </Paper>
          <div>
            <Text w={"max-content"} size="sm">
              {record?.inAcc?.bankName ?? "-"} {record?.inAcc?.number ?? "-"}
            </Text>
            <Text c="dimmed" size="sm">
              {record.inAcc?.shortName ?? ""}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: " Нэхэмжлэх төлөх данс",
    dataIndex: "outAcc",
    render: (record) =>
      record.inAcc ? (
        <Flex gap={10} align="center">
          <Paper withBorder>
            <Avatar src={record.outAcc.icon}>
              <IconPhoto />
            </Avatar>
          </Paper>
          <div>
            <Text w={"max-content"} size="sm">
              {record?.outAcc?.bankName ?? "-"} {record?.outAcc?.number ?? "-"}
            </Text>
            <Text c="dimmed" size="sm">
              {record.outAcc?.shortName ?? ""}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Ангилал",
    dataIndex: "classificationCategory",
    render: (record) =>
      record?.classificationCategory ? (
        <div>
          <Text size="sm">{record?.classificationCategory?.name ?? "-"}</Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.classificationCategory?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    dataIndex: "classificationPriority",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.classificationPriority?.name ?? "-"}
      </Text>
    ),
  },
];

const businessTypes = [
  {
    code: "SUPPLIER",
    name: "Нийлүүлэгч",
  },
  {
    code: "BUYER",
    name: "Худалдан авагч",
  },
];
