import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const confirm = async (id: string, data: object | any) => {
  return httpRequest.put(`/biz/invitation/${id}/confirm`, data);
};

export const create = async (data: object | any) => {
  return httpRequest.post(`/biz/invitation`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/biz/invitation/${id}`);
};

export const respond = async (id: string, data: any) => {
  return httpRequest.put(`/biz/invitation/${id}/respond`, data);
};

export const send = async (id: string, data: any) => {
  return httpRequest.put(`/biz/invitation/${id}/send`, data);
};

export const sent = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/invitation/sent", { offset, filter });
};

export const funder = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/invitation/funder", { offset, filter });
};

export const received = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/invitation/received", { offset, filter });
};

export const business_list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/invitation/business_list", { offset, filter });
};

export const business_suggest = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/invitation/business_suggest", { offset, filter });
};

export const remove = async (id: string) => {
  return httpRequest.del(`/biz/invitation/${id}`);
};

export const onboarding = async (data: object | any) => {
  return httpRequest.post(`/biz/invitation/onboarding`, data);
};

export const update = async (id: string, data: object | any) => {
  return httpRequest.put(`/biz/invitation/${id}`, data);
};
