import { ActionIcon, Button, Drawer, Group } from "@mantine/core";
import { IconDirections, IconUserCircle } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DistributionAreaForm } from "../../components/distribution-area/distribution-area-form";
import { DistributionAreaList } from "../../components/distribution-area/distribution-area-list";
import { StaffSetForm } from "../../components/distribution-area/staff-set-form";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { useScreenDetector } from "../../components/screen-detector";
import { IAuth } from "../../interfaces/IAuth";

export const DistributionArea = () => {
  const { businessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const NET_STF_SET = usePermission("NET_STF_SET");
  const NET_AREA_SET = usePermission("NET_AREA_SET");
  const NET_STF = usePermission("NET_STF");

  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const { isMobile } = useScreenDetector();
  const refTable = useRef<any>(null);

  const reload = async () => {
    await refTable.current.reload();
    setSelected([]);
  };

  return (
    <PageLayout
      title="Хариуцсан ажилтнууд"
      subTitle="Борлуулалтын бүсчлэл, чиглэл, хариуцсан ажилтнууд"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={8}>
          {!isMobile ? (
            <>
              {NET_STF_SET.hasAccess && (
                <Button
                  disabled={selected?.length === 0}
                  onClick={() => {
                    setAction(["staff_set", selected]);
                  }}
                  leftSection={<IconUserCircle />}
                  variant="outline">
                  Ажилтан
                </Button>
              )}
              {NET_AREA_SET.hasAccess && businessType !== "BUYER" && (
                <Button
                  hidden={businessType === "BUYER"}
                  disabled={selected?.length === 0}
                  onClick={() => {
                    setAction(["zone_set", selected]);
                  }}>
                  Чиглэл тохируулах
                </Button>
              )}
            </>
          ) : (
            <>
              {NET_STF_SET.hasAccess && (
                <ActionIcon
                  disabled={selected?.length === 0}
                  onClick={() => {
                    setAction(["staff_set", selected]);
                  }}
                  variant="filled"
                  size={36}
                  color="">
                  <IconUserCircle />
                </ActionIcon>
              )}
              {NET_AREA_SET.hasAccess && businessType !== "BUYER" && (
                <ActionIcon
                  hidden={businessType === "BUYER"}
                  disabled={selected?.length === 0}
                  onClick={() => {
                    setAction(["zone_set", selected]);
                  }}
                  variant="filled"
                  size={36}
                  color="">
                  <IconDirections />
                </ActionIcon>
              )}
            </>
          )}
        </Group>,
      ]}>
      {NET_STF.hasAccess ? (
        <DistributionAreaList refTable={refTable} selected={selected} setSelected={setSelected} action={action} setAction={setAction} reload={() => reload()} />
      ) : (
        NET_STF.accessDenied()
      )}

      <Drawer
        size={"70rem"}
        withCloseButton={false}
        title={false}
        position="right"
        opened={action[0] === "zone_set"}
        onClose={() => {
          setAction([]);
        }}
        padding="lg">
        <DistributionAreaForm title="Бүс, чиглэл тохируулах" action={action} setAction={setAction} subTitle="" reload={() => reload()} />
      </Drawer>
      <Drawer
        size={"70rem"}
        withCloseButton={false}
        title={false}
        position="right"
        opened={action[0] === "staff_set"}
        onClose={() => {
          setAction([]);
        }}
        padding="lg">
        <StaffSetForm title="Харилцагчид ажилтан тохируулах" action={action} setAction={setAction} subTitle="" reload={() => reload()} />
      </Drawer>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Network удирдлага",
  },
  {
    label: "Хариуцсан ажилтнууд",
  },
];
