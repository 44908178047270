import { ActionIcon, Avatar, Badge, Flex, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconMail, IconMailOff, IconMailOpened, IconPhoto } from "@tabler/icons-react";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NetworkInvitationApi } from "../../apis";
import { InvitionForm } from "../../components/invite/invition-form";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IInvitationReceiverItem } from "../../interfaces/IInvitationReceiverItem";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";
import { InvitionPinForm } from "../invite/invition-pin-form";

export function InvitionPartner() {
  const [action, setAction] = useState<any[]>([]);
  const refTable = useRef<any>(null);
  const { invitationStatus = [], invitationTypes = [] } = useSelector((state: any) => state.general);
  const columns = useHeader({
    invitationStatus,
    invitationTypes,
    onClick: (key, record) => {
      setAction([key, record]);
    },
    Tag,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = React.useState({ query: "", type: "NETWORK" });

  return (
    <div>
      <Table ref={refTable} name="network.invitation.received" columns={columns} filters={filters} loadData={(data) => NetworkInvitationApi.received(data!)} />
      <Modal opened={action[0] === "detail"} onClose={() => setAction([])} withCloseButton={false} size="1200px" centered>
        <InvitionForm
          subTitle="Урилга илгээсэн бизнесийн мэдээлэл"
          title={action[1]?.sender?.type === "SUPPLIER" ? "Нийлүүлэгчээс ирсэн урилга" : "Худалдан авагчаас ирсэн урилга"}
          action={action}
          setAction={setAction}
        />
      </Modal>
      <Modal opened={action[0] === "pin"} onClose={() => setAction([])} withCloseButton={false} size="md" centered>
        <InvitionPinForm
          reload={async () => {
            await refTable.current.reload();
          }}
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          title={"Баталгаажуулах пин код"}
          action={action}
          onCancel={setAction}
        />
      </Modal>
    </div>
  );
}

const useHeader = ({
  onClick,
  invitationStatus,
  invitationTypes,
  Tag,
}: {
  onClick: (key: string, record: IInvitationReceiverItem) => void;
  invitationStatus: IReference[];
  invitationTypes: IReference[];
  Tag: any;
}): ColumnType<IInvitationReceiverItem>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    align: "center",
    render: (record) => {
      if (record.invitationStatus === "ACCEPTED")
        return (
          <Tooltip label="Дэлгэрэнгүй харах">
            <ActionIcon color={"green"} variant="light" onClick={() => onClick("detail", record)}>
              <IconMailOpened />
            </ActionIcon>
          </Tooltip>
        );
      if (record.invitationStatus === "REJECTED")
        return (
          <Tooltip label="Дэлгэрэнгүй харах">
            <ActionIcon color={"red"} variant="light" onClick={() => onClick("detail", record)}>
              <IconMailOff />
            </ActionIcon>
          </Tooltip>
        );
      if (record.invitationStatus === "SENT")
        return (
          <Tooltip label="Зөвшөөрөх">
            <ActionIcon color={"indigo"} variant="light" onClick={() => onClick("detail", record)}>
              <IconMail />
            </ActionIcon>
          </Tooltip>
        );
      return record.invitationStatus ?? "N/A";
    },
  },
  {
    title: "Урилга",
    render: (record) => (
      <Text c="primary" w="max-content" size="sm">
        #{record?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Урилгын статус",
    render: (record) =>
      Tag(
        invitationStatus?.find((item: any) => item.code === record.invitationStatus)?.color ?? "blue",
        invitationStatus?.find((item: any) => item.code === record.invitationStatus)?.name ?? "-",
      ),
  },
  {
    title: "Партнер нэр",
    render: (record) => <Text size="sm">{record?.sender?.partnerName ?? "-"}</Text>,
  },
  {
    title: "Бизнес нэр",
    render: (record) =>
      record?.sender?.refCode ? (
        <div>
          <Text size="sm" w="max-content">
            {record?.sender?.profileName ?? "-"}
          </Text>
          <Text c="primary" size="sm">
            #{record?.sender?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Урьсан ажилтан",
    render: (record) =>
      record?.sender?.staff?.firstName ? (
        <Flex gap={10} align="center" w={"max-content"}>
          <Avatar src={record?.sender?.staff?.avatar} />
          <Text size="sm">
            {record?.sender?.staff?.lastName ? record.sender?.staff?.lastName[0] + "." : ""} {record?.sender?.staff?.firstName ?? "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Урьсан и-мэйл хаяг",
    render: (record) => <Text size="sm">{record?.sender?.staff?.email ?? "-"}</Text>,
  },
  {
    title: "Урьсан утасны дугаар",
    render: (record) => <Text size="sm">{record?.sender?.staff?.phone ?? "-"}</Text>,
  },
  {
    title: "Buyer-с ирсэн",
    render: (record) => (
      <Badge variant="light" color={record.sender?.type === "BUYER" ? "primary" : "gray"}>
        {record.sender?.type === "BUYER" ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Supplier-с ирсэн",
    render: (record) => (
      <Badge variant="light" color={record.sender?.type === "SUPPLIER" ? "primary" : "gray"}>
        {record.sender?.type === "SUPPLIER" ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Урилга хүлээн авсан бизнес",
    render: (record) =>
      record?.receiver?.refCode ? (
        <Group w={"max-content"} gap={10}>
          <Avatar src={record?.receiver?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm">{record?.receiver?.profileName ?? "-"}</Text>
            <Text c="indigo" size="sm">
              #{record?.receiver?.refCode ?? "-"}
            </Text>
          </div>
        </Group>
      ) : (
        "-"
      ),
  },
  {
    title: "Төрөл",
    render: (record) => (
      <Text w="max-content" size="sm">
        {invitationTypes?.find((item: any) => item.code === record.type)?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Огноо, цаг",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.invitedDate ? dateTimeFormat(record?.invitedDate) : "-"}
      </Text>
    ),
  },
];
