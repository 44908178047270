import { Alert, Button, Collapse, Divider, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import * as yup from "yup";
import { NetworkSetApi, PaymentTermApi } from "../../apis";
import { Form, IFormRef } from "../../components/form";
import { SelectField } from "../../components/form/select-field";
import { SelectItemField } from "../../components/form/select-item-field";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ITableRef } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { PaymentTermManageList } from "./list";

const schema = yup.object({
  condition: yup.string().required("Заавал бөглөнө!").nullable(),
  businessIds: yup.array().min(0, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  paymentTermId: yup.string().required("Заавал бөглөнө!").nullable(),
  paymentTermStartDate: yup.string().required("Заавал бөглөнө!"),
  paymentTermEndDate: yup.string().required("Заавал бөглөнө!"),
  configType: yup.string().required("Заавал бөглөнө!").nullable(),
});

export function PaymentTermManage() {
  const { user, businessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const { paymentTermConditions, paymentTermConfigTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const breadcrumbs = useBreadcrumb();
  const refTable = useRef<ITableRef | any>();
  const formRef = useRef<IFormRef<any>>(null);
  const [query, setQuery] = useState<string | null>("");
  const NET_PT_SET = usePermission("NET_PT_SET");

  const [filter, setFilter] = useState<{
    condition: null | string;
    configType: null | string;
  }>({
    condition: null,
    configType: null,
  });

  const [data] = useState<any>({
    condition: null,
    businessIds: [],
    paymentTermId: null,
    paymentTermStartDate: null,
    paymentTermEndDate: null,
    configType: undefined,
    date: [],
  });

  const { data: termList = [] } = useSWR<any>(
    `/biz/network/payment_term/select?condition=${JSON.stringify(filter.condition)}&configType=${JSON.stringify(filter.configType)}&query=${JSON.stringify(
      query,
    )}`,
    filter.condition && filter.configType && NET_PT_SET.hasAccess ? async () => await PaymentTermApi.select({ ...filter, query: query }) : null,
    {
      fallback: [],
    },
  );

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await NetworkSetApi.payment_term({
        businessIds: values.businessIds,
        paymentTermId: values.paymentTermId,
        paymentTermStartDate: values.paymentTermStartDate,
        paymentTermEndDate: values.paymentTermEndDate,
      });
      let now = new Date();
      await refTable.current?.reload();
      setFilter({ condition: null, configType: null });
      setQuery(null);
      message.success(`Харилцагчид төлбөрийн нөхцөл амжилттай тохирууллаа . ${dateTimeFormat(now)}`);
    } catch (error: any) {
      message.error(error.message || "");
    }
    setLoading(false);
  };

  let now = new Date();

  return (
    <Form ref={formRef} validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        return (
          <PageLayout
            title={"Төлбөрийн нөхцөл"}
            subTitle={businessType === "SUPPLIER" ? "Харилцагчид төлбөрийн нөхцөл тохируулах" : "Тохируулсан төлбөрийн нөхцөл"}
            breadcrumb={breadcrumbs}>
            <div>
              {NET_PT_SET.hasAccess ? (
                <Paper p="md" withBorder>
                  <Grid>
                    {businessType === "SUPPLIER" && (
                      <>
                        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                          <Input.Wrapper label="Тохиргоо хийсэн" required>
                            <Text c="indigo" fw={600}>
                              {dateTimeFormat(now)}
                            </Text>
                          </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                          <Input.Wrapper label="Ажилтан" required>
                            <Text c="indigo" fw={600}>
                              {user?.lastName} {user?.firstName}
                            </Text>
                          </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col>
                          <Divider />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                          <SelectField
                            onChange={(e: any) => {
                              setFieldValue("paymentTermId", null);
                              setFieldValue("configType", null);
                              setFilter({ condition: e, configType: null });
                              setQuery("");
                            }}
                            options={(paymentTermConditions ?? []).map((item: any) => {
                              return {
                                label: item.name || "",
                                value: item.code || "",
                              };
                            })}
                            name="condition"
                            clearable
                            searchable
                            label="Төлбөрийн хэлбэр:"
                            placeholder="Төлбөрийн хэлбэр"
                            required
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                          <SelectItemField
                            onChange={async (e) => {
                              setFieldValue("paymentTermId", null);
                              setQuery("");
                              setFilter({ ...filter, configType: e });
                            }}
                            maxDropdownHeight={200}
                            disabled={!values.condition}
                            options={(paymentTermConfigTypes ?? [])
                              .filter((item: any) => item.condition === values.condition)
                              .map((item: any, index: number) => {
                                return {
                                  label: item.text || "",
                                  value: item.code || "",
                                  configType: item.code || "",
                                };
                              })}
                            name="configType"
                            clearable
                            searchable
                            label="Нөхцөл код:"
                            placeholder="Нөхцөл код"
                            required
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                          <SelectItemField
                            disabled={!values.condition || !values.configType}
                            name="paymentTermId"
                            required
                            label="Төлбөрийн нөхцөл:"
                            placeholder="Төлбөрийн нөхцөл"
                            clearable
                            searchable
                            onSearchChange={(e) => setQuery(e)}
                            options={(termList ?? []).map((item: any) => {
                              return {
                                label: item?.description || "",
                                value: item?.id || "",
                                configType: item?.refCode || "",
                              };
                            })}
                            maxDropdownHeight={200}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                          <DatePickerInput
                            type="range"
                            value={[
                              values?.paymentTermStartDate ? new Date(values?.paymentTermStartDate) : null,
                              values?.paymentTermEndDate ? new Date(values?.paymentTermEndDate) : null,
                            ]}
                            error={errors.paymentTermStartDate || errors.paymentTermEndDate}
                            placeholder="Нөхцөл эхлэх / дуусах огноо сонгох"
                            label="Нөхцөл эхлэх / дуусах огноо"
                            onChange={async (e) => {
                              setFieldValue("paymentTermStartDate", e[0] ? dayjs(e[0]).format() : null);
                              setFieldValue("paymentTermEndDate", e[1] ? dayjs(e[1]).format() : null);
                            }}
                            valueFormat="YYYY-MM-DD"
                          />
                        </Grid.Col>
                      </>
                    )}
                    <Grid.Col span={12}>
                      <PaymentTermManageList refTable={refTable} values={values} setFieldValue={setFieldValue} />
                      <Collapse in={errors.businessIds} hidden={businessType === "BUYER"}>
                        <Alert mb={"xs"} title={errors.businessIds} color="red">
                          Та бизнес сонгоно уу!
                        </Alert>
                      </Collapse>
                      <Divider my="lg" />
                    </Grid.Col>
                    <Grid.Col span={12} hidden={businessType === "BUYER"}>
                      <Group align="right">
                        <Button type="submit" loading={loading}>
                          Нөхцөл хадгалах
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </Paper>
              ) : (
                NET_PT_SET.accessDenied()
              )}
            </div>
          </PageLayout>
        );
      }}
    </Form>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Төлбөрийн нөхцөл",
  },
];
