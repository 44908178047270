import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; parentId?: string; description: string }) => {
  return httpRequest.post("/biz/distribution_area", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/distribution_area", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/biz/distribution_area/${id}`);
};

export const update = async (id: string, data: { name: string; description: string; parentId?: string }) => {
  return httpRequest.put(`/biz/distribution_area/${id}`, data);
};
