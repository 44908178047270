import { ActionIcon, Button, Modal, Paper, Space, Tabs, Text } from "@mantine/core";
import { IconId, IconIdBadge2, IconMail } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InviteDraftForm } from "../../components/invite-draft/invite-draft-form";
import { InviteDraftPinForm } from "../../components/invite-draft/invite-draft-pin-form";
import { InvitedBuyerList } from "../../components/invition-sent/invited-buyer-list";
import { InvitedSupplierList } from "../../components/invition-sent/invited-supplier-list";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { useScreenDetector } from "../../components/screen-detector";

export const InvitionSentList = () => {
  const breadcrumbs = useBreadcrumb();
  const { businessType } = useSelector((state: any) => state.auth);
  const refTable = useRef<any>(null);
  const { isMobile } = useScreenDetector();
  const navigate = useNavigate();
  const [action, setAction] = useState<any[]>([]);

  const NET_INV_SENT = usePermission("NET_INV_SENT");
  const NET_INV_ONB = usePermission("NET_INV_ONB");

  const [filters, setFilters] = useState({
    query: "",
    status: "",
    type: "NETWORK",
  });

  const reload = async () => {
    await refTable.current.reload();
  };

  return (
    <PageLayout
      title="Илгээсэн урилгын жагсаалт"
      subTitle="Партнеруудад хамтран ажиллахаар илгээсэн урилгууд"
      breadcrumb={breadcrumbs}
      extra={
        NET_INV_SENT.isCreate || NET_INV_SENT.isEdit || NET_INV_ONB.isCreate || NET_INV_ONB.isEdit
          ? [
              !isMobile ? (
                <Button onClick={() => navigate("/sent/send")} key={0}>
                  Урилга илгээх
                </Button>
              ) : (
                <ActionIcon onClick={() => navigate("/sent/send")} key={1} size={36} color="" variant="filled">
                  <IconMail />
                </ActionIcon>
              ),
            ]
          : []
      }>
      <Tabs
        onChange={(e: string | null) => {
          if (e) setFilters({ ...filters, type: e });
        }}
        value={filters.type}
        defaultValue={filters.type}>
        <Tabs.List>
          <Tabs.Tab value="NETWORK" leftSection={<IconIdBadge2 size={18} />}>
            <Text>DeHUB-Network-с</Text>
          </Tabs.Tab>
          <Tabs.Tab value="ONBOARDING" leftSection={<IconId size={18} />}>
            Onboard урилгууд
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>

      <Space h="md" />

      <Paper>
        {businessType === "BUYER" ? (
          <>
            {NET_INV_SENT.isView ? (
              <InvitedSupplierList filters={filters} setFilters={setFilters} refTable={refTable} setAction={setAction} />
            ) : (
              NET_INV_SENT.accessDenied()
            )}
          </>
        ) : (
          <>
            {NET_INV_SENT.isView ? (
              <InvitedBuyerList filters={filters} setFilters={setFilters} refTable={refTable} setAction={setAction} />
            ) : (
              NET_INV_SENT.accessDenied()
            )}
          </>
        )}
      </Paper>
      <Modal withCloseButton={false} opened={action[0] === "draft_invite" || action[0] === "details"} onClose={() => setAction([])} size={"xl"} centered>
        <InviteDraftForm
          refTable={refTable}
          action={action}
          setAction={setAction}
          title={action[1]?.receiver?.type === "SUPPLIER" ? "Нийлүүлэгчид илгээх урилга" : "Худалдан авагчид илгээх урилга"}
          subTitle={
            action[1]?.receiver?.type === "SUPPLIER" ? "Нийлүүлэгчид урилга илгээгч бизнесийн мэдээлэл" : "Худалдан авагчид урилга илгээгч бизнесийн мэдээлэл"
          }
        />
      </Modal>
      <Modal withCloseButton={false} opened={action[0] === "pin" || action[0] === "remove"} onClose={() => setAction([])} size={"md"} centered>
        <InviteDraftPinForm
          reload={reload}
          action={action}
          onCancel={setAction}
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
        />
      </Modal>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Манай илгээсэн урилга",
  },
];
