import { MantineTheme, MantineThemeOverride } from "@mantine/core";
import { Global } from "@mantine/emotion";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        "*, *::before, *::after": {
          border: "0 solid #e5e7eb",
        },
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};

export const themeConfig: MantineThemeOverride = {
  colors: {
    golomt: ["#eef6fc", "#bde0ff", "#8bc1fb", "#73aefa", "#1f70db", "#004c8b", "#144e9d", "#05357e", "#0a2c5f", "#031a3d"],
    sdasd: ["#F8F0FC", "#F3D9FA", "#EEBEFA", "#e591f8", "#DA77F2", "#CC5DE8", "#BE4BDB", "#AE3EC9", "#9C36B5", "#862E9C"],
    primary: ["#fbedfd", "#f4d6f6", "#eaaaee", "#e07be8", "#d754e1", "#9c24a4", "#82138a", "#680f6e", "#510b56", "#4a0a4e"],
  },
  primaryColor: "primary",
  components: {
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 14,
        },
      }),
    },
    Table: {
      styles: () => ({
        highlightOnHover: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      }),
    },
  },
};
