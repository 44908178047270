import { Button, CloseButton, Divider, Grid, Group, Input, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { NetworkInvitationApi } from "../../apis";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { usePermission } from "../permission";
import { InviteBuyerList } from "./invite-buyer-list";

const schema = yup.object({
  toMessage: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна.").nullable(),
  receiverIds: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  partnerName: string | null;
  toMessage: string | null;
  send: boolean;
  receiverIds: any[];
};

export function InviteBuyerForm({
  action,
  setAction,
  title,
  subTitle,
  reload,
}: {
  action: any[];
  setAction: (e: any) => void;
  title: string;
  subTitle: string;
  reload: () => void;
}) {
  const { user } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const NET_INV_SENT = usePermission("NET_INV_SENT");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState<IFormData | any>({
    toMessage: `Сайн байна уу, Тантай DeHUB бизнесийн сүлжээнд холбогдож, хамтран ажиллах хүсэлтэй байна. Хүндэтгэсэн, ${
      user?.lastName ? user?.lastName[0] + ". " : ""
    } ${user.firstName ?? "-"}`,
    send: false,
    receiverIds: [],
    partnerName: (action[0] === "invite" && action[1].partnerName) ?? null,
  });

  const onSubmit = async (values: any, send: boolean) => {
    if (send) setAction(["pin", values]);
    else {
      setLoading(true);
      try {
        await NetworkInvitationApi.create({
          receiverIds: values.receiverIds,
          toMessage: values.toMessage,
          send: false,
        });
        message.success(`Урилга амжилттай хадгалагдлаа. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`);

        await reload();
        setAction([]);
      } catch (error: any) {
        message.error(error.message ?? "Хүсэлт амжилтгүй.");
      }
      setLoading(false);
    }
  };

  let now = new Date();

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setAction && setAction([])} />]}>
            <Paper>
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилгын №" required>
                    <Text c="indigo" fw={500} size="sm">
                      Системээс авто үүснэ
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилга илгээж буй ажилтан" required>
                    <Text c="indigo" fw={500} size="sm">
                      {user?.lastName ? user?.lastName[0] + ". " : ""} {user.firstName ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилгын огноо, цаг" required>
                    <Text c="indigo" fw={500} size="sm">
                      {dayjs(now).format("YYYY-MM-DD HH:mm")}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилга илгээж буй партнер" required>
                    <Text c="indigo" fw={500} size="sm">
                      {data?.partnerName ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Урилга илгээж буй бизнес" required>
                    <Text c="indigo" fw={500} size="sm">
                      {user?.currentBusiness?.profileName ?? "-"}, #{user?.currentBusiness?.refCode ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Санхүү хариуцсан ажилтан" required>
                    <Text c="indigo" fw={500} size="sm">
                      {user?.currentBusiness?.staff?.lastName ?? "-"} {user?.currentBusiness?.staff?.firstName ?? "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="toMessage" label="Урилгад очих зурвас:" placeholder="Урилгад очих зурвас" />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <Divider my="sm" p={0} />
                  <Text component="span" size={"lg"} fw={500}>
                    Урилга хүлээн авах бизнесүүд
                  </Text>
                  <Text size={"sm"} c="gray">
                    Урилга хүлээн авах бизнес сонгох
                  </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text fz={"sm"}>
                    Сонгосон бизнесийн нийт тоо:{" "}
                    <Text fw={700} component="span" color="primary">
                      {values?.receiverIds?.length ?? 0}
                    </Text>
                  </Text>
                  <InviteBuyerList action={action} values={values} setFieldValue={setFieldValue} />
                </Grid.Col>
              </Grid>
              <Divider my="lg" />
              <Group justify="right">
                {NET_INV_SENT.isEdit && (
                  <Button
                    disabled={(values?.receiverIds?.length ?? 0) === 0}
                    loading={loading}
                    onClick={() => {
                      if (values.toMessage && values.receiverIds && values.receiverIds.length > 0) onSubmit(values, false);
                    }}
                    type="submit"
                    variant="light">
                    Хадгалах
                  </Button>
                )}
                {NET_INV_SENT.isCreate && (
                  <Button
                    disabled={(values?.receiverIds?.length ?? 0) === 0}
                    loading={loading}
                    type="submit"
                    onClick={() => {
                      if (values.toMessage && values.receiverIds && values.receiverIds.length > 0) onSubmit(values, true);
                    }}>
                    Илгээх
                  </Button>
                )}
              </Group>
            </Paper>
          </FormLayout>
        );
      }}
    </Form>
  );
}
