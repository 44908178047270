import { Avatar, Checkbox, Flex, Text } from "@mantine/core";
import React from "react";
import { NetworkBusinessApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";

export function RankList({
  action,
  setAction,
  selected,
  setSelected,
  refTable,
}: {
  action: any;
  setAction: any;
  selected: string[];
  setSelected: (e: string[]) => void;
  reload: () => void;
  refTable: any;
}) {
  const columns = useHeader({
    selected,
    onClick: (key: string, item: any) => {
      if (key === "unselect_all") {
        setSelected([]);
      } else if (key === "select_all") {
        setSelected(item);
      } else if (key === "select") {
        if (selected.some((item1: any) => item1.id === item.id)) {
          setSelected([...selected.filter((item1: any) => item1.id !== item.id)]);
        } else setSelected([...selected, item]);
      } else setAction([key, { ...item, businessIds: selected }]);
    },
  });

  const [filters] = React.useState({ query: "" });

  return (
    <div>
      <Table ref={refTable} name="rank.category.list" columns={columns} filters={filters} loadData={(data) => NetworkBusinessApi.list(data!)} />
    </div>
  );
}

const useHeader = ({ onClick, selected }: { onClick: (e: string, e1: any) => void; selected: any[] }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "",
    renderTitle: (data: any) => {
      return (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              onClick("select_all", data?.rows ?? []);
            } else onClick("unselect_all", []);
          }}
          onClick={() => {}}
          checked={(selected?.length ?? 0) === data?.rows?.length}
        />
      );
    },
    width: "30px",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={selected?.some((item: any) => item?.id === record?.id)}
        />
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.regNumber ?? "-"}</Text>,
  },
  {
    title: "Партнерийн нэр",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.partner?.businessName ?? "-"}
        </Text>
        <Text c="indigo" size="sm">
          #{record?.partner?.refCode ?? "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.profileName}
        </Text>
        <Text c="indigo" size="sm">
          #{record?.refCode ?? "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (!!record?.branch?.name)
        return (
          <div>
            <Text size="sm" w="max-content">
              {record?.branch?.name ?? "-"}
            </Text>
            <Text c="indigo" size="sm">
              #{record?.branch?.refCode ?? "-"}
            </Text>
          </div>
        );
      else return "-";
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    width: "200px",
    render: (record) =>
      record?.classificationCategory ? (
        <div>
          <Text size="sm">{record?.classificationCategory?.name ?? "-"}</Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.classificationCategory?.refCode ?? "-"}
          </Text>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.classificationPriority?.name)
        return (
          <div>
            <Text size="sm">{record?.classificationPriority?.name ?? "-"}</Text>
            <Text c="indigo" size="sm">
              <PopoverText>{record?.classificationPriority?.description ?? "-"}</PopoverText>
            </Text>
          </div>
        );
      else return "-";
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.classificationDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Тохиргоо хийсэн",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.classificationSetUser?.firstName) {
        return (
          <Flex gap={10} align="center">
            <Avatar src={record?.classificationSetUser?.avatar} />
            <Text size="sm" w="max-content">
              {record?.classificationSetUser?.lastName ? record?.classificationSetUser?.lastName[0] + "." : ""} {record?.classificationSetUser?.firstName}
            </Text>
          </Flex>
        );
      } else return "-";
    },
  },
  {
    title: "Өөрчлөлт хийсэн огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <Text size="sm">{record?.classificationSetDate ? dateTimeFormat(record?.classificationSetDate) : "-"}</Text>,
  },
];
