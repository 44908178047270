import { Button, CloseButton, Divider, Flex, Grid, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { GeneralApi, PaymentTermApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/Message";
import HttpHandler from "../../utils/http-handler";
import { Form } from "../form";
import { SelectItemField } from "../form/select-item-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

type Props = {
  onCancel: () => void;
  title: string;
  subTitle: any;
  action: any[];
  payload?: any;
  reload?: any;
};

type IFormData = {
  configType: string;
  condition: string;
  month: string;
  paymentDay: string;
  expireDayCount: string;
  confirmationDay: string | number | null | undefined;
  advancePercent: string | number | null | undefined;
};

const schema = yup.object({
  condition: yup.string().required("Заавал бөглөнө!").nullable(),
  configType: yup.string().required("Заавал бөглөнө!").nullable(),
  expireDayCount: yup
    .number()
    .when("configType", (configType, schema) => {
      return typeof configType === "string" && configType === "INV_NET_X"
        ? schema.min(1, "Багадаа 1 байна").max(90, "Ихдээ 90 байна").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!")
        : schema.notRequired().nullable();
    })
    .when("configType", (configType, schema) => {
      return typeof configType === "string" && configType === "INV_MFI_X"
        ? schema.min(1, "Багадаа 1 байна").max(90, "Ихдээ 90 байна").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!")
        : schema.notRequired().nullable();
    })
    .optional(),
});

export function InvConfigTermForm({ title, subTitle, onCancel, action, reload }: Props) {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { paymentTermConfigTypes = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    configType: "INV_NET_X",
    condition: params.type!,
    expireDayCount: undefined,
    paymentDay: undefined,
    advancePercent: undefined,
    confirmationDay: undefined,
    ...(action && action[0] === "update" ? action[1] : action && action[0] === "detail" ? action[1] : {}),
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      if (action[0] === "update") {
        await PaymentTermApi.update(action && action[1]?.id, {
          condition: data.condition,
          configType: data.configType,
          expireDayCount: data.expireDayCount,
        });
        message.success("Амжилттай нөхцөлийг засварласан!");
      } else {
        await PaymentTermApi.create({
          condition: data.condition,
          configType: data.configType,
          expireDayCount: data.expireDayCount,
        });
        message.success("Амжилттай шинэ нөхцөл нэмлээ!");
      }
      const res = await GeneralApi.init();
      dispatch(initGeneral(res));
      setLoading(false);
      reload();
      onCancel && onCancel();
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ setFieldValue, errors }) => {
        console.log(errors);

        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <Divider mb="sm" />
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={6}>
                <SelectItemField
                  searchable
                  name="configType"
                  required
                  disabled
                  placeholder="Нөхцөл код / тайлбар"
                  label="Нөхцөл код / тайлбар"
                  clearable
                  onChange={(e) => {
                    setFieldValue("expireDayCount", undefined);
                  }}
                  options={
                    paymentTermConfigTypes
                      ?.filter((item: any) => item.condition === params.type)
                      .map((item: IReference) => {
                        return {
                          label: item?.text || "-",
                          value: item.code,
                        };
                      }) || []
                  }
                  // filter={(value: any, item: any) =>
                  //   item?.label?.toLowerCase().includes(value?.toLowerCase().trim()) || item?.configType?.toLowerCase().includes(value?.toLowerCase().trim())
                  // }
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextField disabled={action[0] === "detail"} label="Хоног" name="expireDayCount" placeholder={"Хоног"} type="number" required />
              </Grid.Col>
              <Grid.Col>
                <Divider />
                <Flex mt="md" justify="flex-end" gap={10}>
                  <Button variant="outline" onClick={() => onCancel && onCancel()}>
                    Болих
                  </Button>
                  {action && action[0] !== "detail" && (
                    <Button loading={loading} variant="filled" type="submit">
                      Хадгалах
                    </Button>
                  )}
                </Flex>
              </Grid.Col>
            </Grid>
          </FormLayout>
        );
      }}
    </Form>
  );
}
