import { Input, MantineSize, Space } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import OtpInput from "react18-input-otp";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  type?: string;
  isInputNum?: boolean;
  isInputSecure?: boolean;
  shouldAutoFocus?: boolean;
  isDisabled?: boolean;
  separator?: any;
  numInputs?: number;
  size?: MantineSize;
};

export function OtpField({ name, label, disabled = false, required = false, isInputSecure = false, size, numInputs }: Props) {
  const { value, error, onChange } = useField(name);
  const { classes, cx } = useStyle();

  const sizes = (type: MantineSize | undefined) => {
    if (type === "lg") {
      return classes.lg;
    } else if (type === "md") {
      return classes.md;
    } else if (type === "sm") {
      return classes.sm;
    } else return classes.sm;
  };

  return (
    <Input.Wrapper label={label} error={error} required={required}>
      <OtpInput
        isInputSecure={isInputSecure}
        isDisabled={disabled}
        errorStyle={classes.error}
        placeholder={"******"}
        hasErrored={!!error}
        className={cx(classes.input, sizes(size))}
        value={value}
        onChange={(e: number) => {
          onChange(e && `${e}`);
        }}
        numInputs={numInputs || 6}
        separator={<span></span>}
      />
      <Space h={"5px"} />
    </Input.Wrapper>
  );
}

const useStyle = createStyles((theme) => {
  return {
    input: {
      display: "flex",
      gap: "8px",
      [`input`]: {
        border: "1px solid #c1c2c5",
        borderRadius: "4px",
      },
      [`input:focus`]: {
        border: `1px solid ${theme.colors.grape[6]}!important`,
        outline: "none",
      },
    },
    error: {
      border: "1px solid red!important",
      color: "red",
    },
    sm: {
      [`input`]: {
        height: "36px",
        minWidth: "36px",
      },
    },
    md: {
      [`input`]: {
        height: "42px",
        minWidth: "42px",
      },
    },
    lg: {
      [`input`]: {
        height: "50px",
        minWidth: "50px",
        lineHeight: "48px",
      },
    },
  };
});
