import { Button, CloseButton, Divider, Grid, Group, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi, NetworkInvitationApi } from "../../apis";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .typeError("Зөвхөн тоон утга оруулна")
    .max(999999, "Хэт урт sбайна!")
    .min(0, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

type IFormData = {
  pin: string | null;
};

export function InvitePinForm({
  action,
  onCancel,
  title,
  subTitle,
  reload,
}: {
  action: any[];
  onCancel: (e: any) => void;
  title: string;
  subTitle: string;
  reload: () => void;
}) {
  const { businessType } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);

  const [data] = useState<IFormData>({
    pin: null,
  });

  const createInvite = async () => {
    setLoading(true);
    try {
      await NetworkInvitationApi.create({
        receiverIds: action[1]?.receiverIds,
        toMessage: action[1]?.toMessage,
        send: true,
      });

      let now = new Date();
      message.success(`Та ${action[1]?.receiverIds?.length} нийлүүлэгч бизнест урилга илгээлээ. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`);
      // navigate("/sent/send");
      reload();
      onCancel([]);
    } catch (error) {
      message.error("Хүсэлт амжилтгүй боллоо.");
      onCancel([]);
    }
    setLoading(false);
  };

  const onSubmit = async (values: IFormData) => {
    try {
      let res = await AuthApi.checkPin({ pin: `${values?.pin}` });

      if (res.success) {
        createInvite();
      }
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй боллоо.");
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel([])} />]}>
            <Paper>
              <Grid>
                <Grid.Col span={12}>
                  {action[0] === "draft_invite" ? (
                    <Text component="p" fz="sm" fw={500}>
                      Та "{action[1]?.sender?.profileName}" бизнест{" "}
                      {businessType === "SUPPLIER" ? " “Худалдан авагч бизнесээр ажиллах урилга”" : "Нийлүүлэгч бизнесээр ажиллах урилга”"} илгээх гэж байна.
                    </Text>
                  ) : (
                    <Text component="p" fz="sm" fw={500}>
                      Та {action[1]?.receiverIds?.length} бизнест{" "}
                      {businessType === "SUPPLIER" ? " “Худалдан авагч бизнесээр ажиллах урилга”" : "Нийлүүлэгч бизнесээр ажиллах урилга”"} илгээх гэж байна.
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col span={12}>
                  <OtpField name="pin" label="Баталгаажуулах ПИН код" placeholder="" required />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text component="p" fz="sm" fw={500}>
                    Таны урьж буй {action[0] === "draft_invite" ? "бизнест" : "бизнесүүдэд"} урилга илгээгдэх болно.
                  </Text>
                </Grid.Col>
              </Grid>
              <Divider my="lg" />
              <Group justify="right">
                <Button onClick={() => onCancel([])} variant="default">
                  Болих
                </Button>
                <Button loading={loading} type="submit">
                  Батлах
                </Button>
              </Group>
            </Paper>
          </FormLayout>
        );
      }}
    </Form>
  );
}
