import { Button, CloseButton, Divider, Grid, Group, Input, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NetworkInvitationApi } from "../../apis";
import { message } from "../../utils/Message";
import { FormLayout } from "../layout";
import { usePermission } from "../permission";

export function InvitionForm({ action, setAction, title, subTitle }: { action: any[]; setAction: (e: any) => void; title: string; subTitle: string }) {
  const { invitationStatus } = useSelector((state: any) => state.general);
  const NET_INV_RES = usePermission("NET_INV_RES");

  const [data, setData] = useState<any>({
    id: null,
    toMessage: null,
    sender: {
      id: null,
      regNumber: null,
      partnerName: null,
      partnerNameEng: null,
      refCode: null,
      profileName: null,
      logo: null,
      type: null,
      partner: {
        refCode: null,
        businessName: null,
      },
    },
    receiver: {
      id: null,
      regNumber: null,
      partnerName: null,
      refCode: null,
      profileName: null,
    },
    senderStaff: {
      firstName: null,
      lastName: null,
      avatar: null,
    },
    senderFinStaff: {
      firstName: null,
      lastName: null,
      avatar: null,
    },
    invitationStatus: null,
  });

  const getData = async (id: string) => {
    try {
      let res = await NetworkInvitationApi.get(id);
      setData({ ...res });
    } catch (error) {
      message.error("");
    }
  };

  useEffect(() => {
    if (action[1]?.id) {
      getData(action[1].id);
    }
  }, [action]);

  return (
    <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setAction && setAction([])} />]}>
      <Paper>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Урилгын №:">
              <Text c="indigo" fw={500} size="sm">
                {data.refCode ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Урилга ирсэн огноо, цаг:">
              <Text c="indigo" fw={500} size="sm">
                {data.invitedDate ? dayjs(data.invitedDate).format("YYYY-MM-DD HH:mm") : "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Урилга илгээж буй ажилтан:">
              <Text c="indigo" fw={500} size="sm">
                {data?.senderUser?.lastName ?? ""} {data?.senderUser?.firstName ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Урилга илгээсэн партнер:">
              <Text c="indigo" fw={500} size="sm">
                {data?.sender?.partnerName ?? "-"}, #{data?.sender?.partner?.refCode ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Урилга илгээсэн бизнес:">
              <Text c="indigo" fw={500} size="sm">
                {data?.sender?.profileName ?? "-"}, #{data?.sender?.refCode ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Санхүү хариуцсан ажилтан:">
              <Text c="indigo" fw={500} size="sm">
                {data?.senderFinStaff?.lastName ?? ""} {data?.senderFinStaff?.firstName ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={12}>
            <Input.Wrapper label="Урилгын тайлбар:">
              <Text c="indigo" fw={500} size="sm">
                {data?.toMessage ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <Divider my="sm" p={0} />
            <Text component="span" size={"lg"} fw={500}>
              Урилга хүлээн авсан
            </Text>
            <Text size={"sm"} c="gray">
              Урилга хүлээн авсан бизнесийн мэдээлэл
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Аж ахуйн нэгжийн нэр:">
              <Text c="indigo" fw={500} size="sm">
                {data?.receiver?.partnerName ?? "-"}, #{data?.receiver?.partner?.refCode ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Татвар төлөгчийн дугаар:">
              <Text c="indigo" fw={500} size="sm">
                {data?.receiver?.regNumber ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Бизнесийн нэр:">
              <Text c="indigo" fw={500} size="sm">
                {data?.receiver?.profileName ?? "-"}, #{data?.receiver?.refCode ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Бизнесийн нэр /Латин/:">
              <Text c="indigo" fw={500} size="sm">
                {data?.receiver?.profileNameEng ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Төлөв:">
              <Text c="indigo" fw={500} size="sm">
                {invitationStatus.find((item: any) => item.code === data.invitationStatus)?.name ?? "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          {data?.invitationStatus === "ACCEPTED" || data?.invitationStatus === "REJECTED" ? (
            ""
          ) : (
            <Grid.Col span={12}>
              <div>
                {NET_INV_RES.hasAccess ? (
                  <>
                    <Divider my="lg" />
                    <Group justify="right">
                      <Button variant="light" color="red" onClick={() => setAction(["pin", { accept: false }, data])}>
                        Татгалзах
                      </Button>
                      <Button variant="light" onClick={() => setAction(["pin", { accept: true }, data])}>
                        Зөвшөөрөх
                      </Button>
                    </Group>
                  </>
                ) : (
                  <div></div>
                )}
              </div>
            </Grid.Col>
          )}
        </Grid>
      </Paper>
    </FormLayout>
  );
}
