import { Paper, Tabs } from "@mantine/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { InviteBuyer } from "../../components/invition-sent/invite-buyer";
import { InviteSupplier } from "../../components/invition-sent/invite-supplier";
import { PageLayout } from "../../components/layout";
import { OnBoardingForm } from "../../components/onboarding/onboarding-form";
import { usePermission } from "../../components/permission";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let window: boolean = false;

export const InvitionSend = () => {
  const { businessType } = useSelector((state: any) => state.auth);
  const NET_INV_SENT = usePermission("NET_INV_SENT");
  const breadcrumbs = useBreadcrumb();

  useEffect(() => {
    if (businessType) window = true;
  }, [businessType]);

  return (
    <PageLayout
      title={businessType === "SUPPLIER" ? "Худалдан авагч бизнес урих" : "Нийлүүлэгч бизнесийг урих"}
      subTitle="Та урилга илгээх гэж буй бизнесээ DeHUB Network-д бүртгэлтэй эсэхийг шалгана уу."
      breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="NETWORK">
          <Tabs.List>
            <Tabs.Tab value="NETWORK">DeHUB Network-с урих</Tabs.Tab>
            <Tabs.Tab value="ONBOARDING">Onboarding урилга</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="NETWORK">
            {NET_INV_SENT.isCreate ? <>{businessType === "SUPPLIER" ? <InviteBuyer /> : <InviteSupplier />}</> : NET_INV_SENT.accessDenied()}
          </Tabs.Panel>
          <Tabs.Panel value="ONBOARDING">{NET_INV_SENT.isCreate ? <OnBoardingForm /> : NET_INV_SENT.accessDenied()}</Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    to: "/sent",
    label: "Манай илгээсэн урилга",
  },
  {
    label: "Бизнес урих",
  },
];
