import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const checkPin = async (data: any) => {
  return httpRequest.put("/2fa/auth/pin/check", data);
};

export const changePin = async (data: any) => {
  return httpRequest.put("/2fa/auth/pin", data);
};

export const createPin = async (data: any) => {
  return httpRequest.post("/2fa/auth/pin", data);
};

export const me = async () => {
  const res = await httpRequest.get("/biz/auth/me");
  return res;
};
