import { TextInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  required?: boolean;
  type?: string;
};

export function TextField({ name, label, placeholder, disabled = false, required = false, type = "text" }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <TextInput
      type={type}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      withAsterisk={required}
    />
  );
}
