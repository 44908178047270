import { Grid, Tabs } from "@mantine/core";
import React from "react";
import { DirectionList } from "../../components/directory/direction-list";
import { PageLayout } from "../../components/layout";

export const DirectoryList = () => {
  const breadcrumbs = useBreadcrumb();
  const [filters, setFilters] = React.useState({ query: "", type: "ALL" });

  return (
    <PageLayout title="Платформын нэгдсэн лавлах" subTitle="Лавлах мэдээлэл" breadcrumb={breadcrumbs}>
      <Tabs
        orientation="vertical"
        value={filters.type}
        onChange={(e: string | null) => {
          if (e) setFilters({ ...filters, type: e });
        }}
        variant="pills"
        defaultValue={filters.type}>
        <Grid w={"100%"}>
          <Grid.Col span={3}>
            <Tabs.List>
              <Tabs.Tab value="ALL">Бүгд</Tabs.Tab>
              <Tabs.Tab value="TERM">Төлбөрийн нөхцөл</Tabs.Tab>
              <Tabs.Tab value="DIRECTORY">Барааны лавлах</Tabs.Tab>
            </Tabs.List>
          </Grid.Col>
          <Grid.Col span={9}>
            <Tabs.Panel value="ALL">
              <DirectionList />
            </Tabs.Panel>
            <Tabs.Panel value="TERM">
              <DirectionList />
            </Tabs.Panel>
          </Grid.Col>
        </Grid>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Лавлах мэдээлэл",
  },
];
