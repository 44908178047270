import { Button, Paper } from "@mantine/core";
import { IconCaretRight } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { NetworkReferenceApi } from "../../apis";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";

export function DirectionList() {
  const refTable = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = useHeader({
    navigate,
    dispatch,
  });

  const [filters] = React.useState({ query: "" });

  return (
    <div>
      <Paper p={"lg"} withBorder>
        <Table ref={refTable} name="network.reference.list" columns={columns} filters={filters} loadData={(data) => NetworkReferenceApi.list(data!)} />
      </Paper>
    </div>
  );
}

const useHeader = ({ navigate }: any): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Гарчиг",
    dataIndex: "name",
    width: "200px",
    render: (record) => `${record?.name}`,
  },
  {
    title: "Тайлбар",
    width: "200px",
    dataIndex: "refCode",
    render: (record) => <PopoverText>{record?.description || "-"}</PopoverText>,
  },
  {
    title: "Үйлдэл",
    align: "center",
    width: "10px",
    render: (record) => {
      return (
        <Button
          size="xs"
          onClick={() => {
            navigate(`/directory/${record?.id}`);
          }}
          variant="light"
          rightSection={<IconCaretRight size={18} stroke={2} />}>
          Цааш нь
        </Button>
      );
    },
  },
];
