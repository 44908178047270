import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/network", { offset, filter });
};

export const client_classification = async (data: any) => {
  return httpRequest.put("/biz/network/client_classification", data);
};

export const distribution_area = async (data: any) => {
  return httpRequest.put("/biz/network/distribution_area", data);
};
