import { Button, CloseButton, Divider, Grid, Group, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi, NetworkInvitationApi } from "../../apis";
import { message } from "../../utils/Message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  responseMessage: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  pin: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .typeError("Зөвхөн тоон утга оруулна")
    .max(999999, "Хэт урт sбайна!")
    .min(0, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
});

type IFormData = {
  id: string;
  responseMessage: string | null;
  pin: string | null;
  senderProfileName: string | null;
  accept: boolean;
};

export function InvitionPinForm({
  action,
  onCancel,
  title,
  subTitle,
  reload,
}: {
  action: any[];
  onCancel: (e: any) => void;
  title: string;
  subTitle: string;
  reload: () => void;
}) {
  const { theme } = useStyle();
  const { businessType } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);

  const [data] = useState<IFormData>({
    id: action[2]?.id,
    pin: null,
    // responseMessage: "Сайн байна уу {sender_first_name}, Урилга хүлээн авлаа, Хамтран ажиллах болсондоо баяртай байна. Хүндэтгэсэн, {reciever_first_name}",
    senderProfileName: action[2]?.sender?.profileName || null,
    accept: action[1]?.accept || false,
    responseMessage: action[1]?.accept
      ? `Сайн байна уу ${action[2] && action[2]?.senderUser?.firstName}, Урилга хүлээн авлаа, Хамтран ажиллах болсондоо баяртай байна. Хүндэтгэсэн, ${
          (action[2] && action[2]?.receiver?.staff?.firstName) || "-"
        }`
      : "",
  });

  const confirm = async (values: any) => {
    try {
      let now = new Date();
      await NetworkInvitationApi.respond(values?.id, { accept: values?.accept, responseMessage: values?.responseMessage });
      message.success(
        `Та "${values?.senderProfileName || "****"}"-н урилгыг ${values.accept ? "зөвшөөрлөө" : "татгалзлаа"}. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`,
      );
      reload();
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй боллоо.");
    }
    onCancel([]);
  };

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      let res = await AuthApi.checkPin({ pin: `${values.pin}` });

      if (res.success) {
        confirm(values);
      }
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй боллоо.");
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel([])} />]}>
            <Paper>
              <Grid>
                <Grid.Col span={12}>
                  <Text component="p" fz="sm" fw={500}>
                    Та {values.senderProfileName} бизнесээс ирүүлсэн{" "}
                    {businessType === "SUPPLIER" ? "Худалдан авагчийн бизнесээр ажиллах урилга" : "Нийлүүлэлтийн бизнесээр ажиллах урилга"} -д
                    <Text color={values?.accept ? theme.colors.green[6] : theme.colors.red[6]} fw={500} component="span" px={"xs"}>
                      {values?.accept ? "“Зөвшөөрсөн”" : "Татгалзах"}
                    </Text>
                    хариу өгөх гэж байна.
                  </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                  <OtpField name="pin" label="Баталгаажуулах ПИН код" placeholder="" required />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField label="Урилгын хариу тайлбар" name="responseMessage" placeholder="Урилгын хариу тайлбар энд бичнэ үү." required />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider my="lg" />
                  <Group justify="right">
                    <Button onClick={() => onCancel([])} variant="default">
                      Болих
                    </Button>
                    <Button loading={loading} type="submit">
                      Батлах
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Paper>
          </FormLayout>
        );
      }}
    </Form>
  );
}

const useStyle = createStyles(() => ({}));
